import http from "@/utils/http";
import { Message } from "@arco-design/web-react";
// 获取所有项目列表
export const getProjectList = async (name?: string) => {
  try {
    const result = await http.get(`/api/v1/space/project?keyword=${name}`);
    return result.data.items || [];
  } catch (err) {
    return [];
  }
};
export const createProject = async (data: {
  name: string;
  memberIds: string[];
}): Promise<number> => {
  const result = await http.post(`/api/v1/space/project`, {
    name: data.name,
    member_uuid: data.memberIds,
  });
  return result.data.id;
};

// 通过项目获取所有项目下面成员
export const GetProjectMemberIds = async (id: number) => {
  try {
    const result = await http.get(`/api/v1/space/project/${id}/members`);
    return result.data.uuids;
  } catch (e) {
    console.log(e);
  }
};

// 修改项目
export const editProjectProject = async (data: {
  name: string;
  id: number;
  memberIds?: string[];
}) => {
  const result = await http.put(`/api/v1/space/project/${data.id}`, {
    name: data.name,
    member_uuid: data.memberIds || [],
  });
  return result;
};

//删除项目
export const deleteProject = async (id: number) => {
  const result = await http.delete(`/api/v1/space/project/${id}`);
  return result;
};
