import http from "@/utils/http";

export interface IAliyunRequest {
  filename: string;
  floderId?: number;
  projectId: number;
  size: number;
}

export interface IAliOssToken {
  accessid: string;
  callback: string;
  dir: string;
  expire: string;
  host: string;
  policy: string;
  signature: string;
}

export const getUploadToken = async (
  data: IAliyunRequest,
): Promise<IAliOssToken> => {
  try {
    const result = await http.post(`/api/v1/space/oss/upload`, {
      name: data.filename,
      folder_id: data.floderId,
      project_id: data.projectId,
      method: "media",
      size: data.size,
    });
    return result.data;
  } catch (error) {
    console.error("Error fetching upload token:", error);
    throw error;
  }
};
