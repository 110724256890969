import { makeAutoObservable } from "mobx";
import { ICaptionError, ISencesError } from "./type";

class CheckStore {
  private sencesError: ISencesError = [];
  private captionError: ICaptionError = [];
  private globalError: string;
  constructor() {
    makeAutoObservable(this);
  }

  public getGlobalError() {
    return this.globalError;
  }

  public setGlobalError(e: string) {
    this.globalError = e;
  }

  public getCaptionError() {
    return this.captionError;
  }

  public setCaptionError(e: ICaptionError) {
    this.captionError = e;
  }

  public getSencesError() {
    return this.sencesError;
  }

  public setSencesError(e: ISencesError) {
    this.sencesError = e;
  }
}

const checkStore = new CheckStore();
export default checkStore;
