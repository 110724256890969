export const setTokenToLocalStorage = (token) => {
  window.localStorage.setItem("Token", token);
};

export const getTokenToLocalStorage = (): string => {
  return window.localStorage.getItem("Token");
};

export const clearToken = () => {
  window.localStorage.removeItem("Token");
};
