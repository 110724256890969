/*
 * 获取工程列表
 */

import http from "@/utils/http";
import { clearEmpty } from "../common";
import { toNumberList } from "./script";

export interface IGetEngineeringListResponse {
  aspect: string;
  created_at: number;
  id: string;
  owner_uuid: string;
  project_id: string;
  title: string;
  updated_at: number;
}

export interface IEngineeringRequest {
  project_id: number;
  type?: string;
  keyword?: string;
  sort_field?: string;
  sort_sc?: string;
  page: number;
  page_size: number;
}

export const getEngineeringList = async (
  e: IEngineeringRequest,
): Promise<{
  data: IGetEngineeringListResponse[];
  total: number;
}> => {
  const params = clearEmpty(e);
  const result = await http.get(`/api/v1/space/session`, {
    params: params,
  });
  return { data: result.data.items, total: result.data.total };
};

interface createEngineeringRequest {
  title: string;
  project_id: string;
  aspect: string;
  width: number;
  heigth: number;
}

export interface CreateEngineeringResponse {
  aspect: string;
  created_at: number;
  id: string;
  owner_uuid: string;
  project_id: string;
  title: string;
  updated_at: number;
  content: string;
}

// 删除工程
export const deleteEnginering = async (
  ids: string[] | string | number | number[],
) => {
  const id = toNumberList(ids);
  await http.post(`/api/v1/space/session/batch/operation`, {
    type: 1,
    ids: id,
  });
};

export const CopyEnginering = async (
  ids: string[] | string | number | number[],
  target_project_id: number,
) => {
  const id = toNumberList(ids);
  await http.post(`/api/v1/space/session/batch/operation`, {
    type: 2,
    ids: id,
    target_project_id,
  });
};

export const moveEnginering = async (
  ids: string[] | string | number | number[],
  target_project_id: number,
) => {
  const id = toNumberList(ids);
  await http.post(`/api/v1/space/session/batch/operation`, {
    type: 3,
    ids: id,
    target_project_id,
  });
};

//创建工程
export const createEngineering = async (
  // e: IGetEngineeringListResponse,
  e: any,
): Promise<CreateEngineeringResponse> => {
  const result = await http.post(`/api/v1/space/session`, { ...e, type: 1 });
  return result?.data?.item;
};

//修改工程
export const editEngineering = async (e): Promise<any> => {
  await http.put(`/api/v1/space/session/${e.id}`, e);
};

export const toIdGetEngineer = async (
  id: number,
  // ): Promise<CreateEngineeringResponse> => {
): Promise<any> => {
  const result = await http.get(`/api/v1/space/session/${id}/session`);
  return result.data.item;
};

export const toIdGetEngineerContent = async (id: number): Promise<string> => {
  const result = await http.get(`/api/v1/space/session/${id}/content`);
  return result.data.content;
};
