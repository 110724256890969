import http from "@/utils/http";

export interface SearchScriptQuery {
  project_id?: number;
  keyword?: string;
  sort_field?: string;
  sort_sc?: string;
  page?: number;
  page_size?: number;
}

export interface IGraphQScriptItem {
  content: string;
  created_at: number;
  creator: string;
  creator_name: string;
  id: number;
  revision: number;
  team_id: number;
  title: string;
  updated_at: number;
  creator_avatar: string;
  used_count: number;
}

export const queryFields: string[] = [
  "id",
  "title",
  "content",
  "used_count",
  "created_at",
  "updated_at",
  "creator",
  "creator_name",
  "creator_avatar",
  "revision",
  "team_id",
];

const generateGraphQLQuery =
  (data: SearchScriptQuery) =>
  (fields: string[]): string => {
    const selectedFields = fields.join("\n");
    const searchParams: string[] = [];
    if (data.keyword !== undefined)
      searchParams.push(`keyword: "${data.keyword}"`);
    if (data.sort_field !== undefined)
      searchParams.push(`sort_field: "${data.sort_field}"`);
    if (data.project_id !== undefined)
      searchParams.push(`project_id: ${data.project_id}`);
    if (data.page !== undefined) searchParams.push(`page: ${data.page}`);
    if (data.page_size !== undefined)
      searchParams.push(`page_size: ${data.page_size}`);
    if (data.sort_field !== undefined)
      searchParams.push(`sort_sc: "${data.sort_sc}"`);
    const searchStr = searchParams.join(", ");
    return `{
        script_list (${searchStr}){
        items{ ${selectedFields} } 
        participle
        sum
      }
    }`;
  };

export const getScriptList = async (
  data: SearchScriptQuery,
): Promise<{
  data: IGraphQScriptItem[];
  total: number;
}> => {
  const result = await http.post(
    `/api/v1/graphql/`,
    generateGraphQLQuery(data)(queryFields).toString(),
    {
      headers: {
        "Content-Type": "application/graphql",
      },
    },
  );
  return {
    data: result?.data?.script_list?.items || [],
    total: result?.data?.script_list?.sum || 0,
  };
};
