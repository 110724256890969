import { makeAutoObservable } from "mobx";
import {
    Message,
} from "@arco-design/web-react";
import {
    team_invite_invite,
    team_invite_meber
} from "@/services/invite";
import { setTokenToLocalStorage } from "@/utils/token";
class Invite_to {

    token = null;
    user_info = {

    }
    request_id = {
    }
    isSuccess = false;
    isLoading = false;
    codestate = null;
    name = ""
    merbname = ''
    constructor() {
        makeAutoObservable(this);
    }
    /***
     * 修改状态 
     */
    someMethod(data) {
        console.log(data);

        const { token, user_info, request_id } = data;
        this.token = token;
        this.user_info = user_info;
        this.request_id = request_id
        // your code here

    }


    /***
     * 获取当前code 状态值 
     */
    async invite_invite_to(datalist) {
        try {
            await team_invite_invite({
                code: datalist
            }).then((res) => {
                // if (res?.code == 0 && res?.message == "OK") {
                if (res?.data) {
                    this.codestate = res.data.invite_state.state
                    this.name = res.data.invite_state.team_name
                    this.merbname = res.data.invite_state.nickname
                    // Message.success("审核当前");
                } else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error('failed:', error);
        }
        finally {
        }
    }
    /***
     *  邀请团队 ———点击加入当前团队
     */
    async invite_meber_to(datalist) {
        try {
            return await team_invite_meber(datalist).then((res) => {
                if (res?.code == 0 && res?.message == "OK") {
                    Message.success("成功");
                    return "111"
                } else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error('failed:', error);
        }
        finally {

        }
    }
}

const Invite = new Invite_to();
export default Invite;
