// import http from "@/utils/http"

import axios from "axios";
import http from "@/utils/http";
import { isNumber } from "@tiptap/core";
/**
 * 根据团队名称-创建团队-得到团队id
 * **/
export async function team_use(params): Promise<any> {
    try {
        console.log(params);
        const res = await http.post("/api/v1/team/", params);
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}
/**
 *
 * 根据团队id查找团队
 * */
export async function team_id_use(params): Promise<any> {
    try {
        const res = await http.get(`/api/v1/team/${params.id}`);
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}
/**
 *
 * 根据token获取当前团队信息
 * */
export async function team_detailserach(): Promise<any> {
    try {
        const res = await http.get(`/api/v1/team/detail`);
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}
/**
 * uuid 查询 当前团队
 * **/
export async function team_membet_uuidget(params): Promise<any> {
    try {
        console.log(params);
        const res = await http.get(`/api/v1/team/get_team/by_uuid`, { params });
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}

/**
 *  列出所有角色
 * */
export async function team_role(params): Promise<any> {
    try {
        const res = await http.get("/api/v1/team/role", { params });
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}
/**
 * 创建成员
 * */
export async function team_meber(params): Promise<any> {
    try {
        const res = await http.post("/api/v1/team/member", params);
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}
/**
 *  查找成员
 * */
export async function team_merber_search(params): Promise<any> {
    try {
        const res = await http.get("/api/v1/team/member/search", { params });
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}
/**
 *  删除成员
 * */
export async function team_member_delete(data): Promise<any> {
    try {
        const res = await http.delete("/api/v1/team/member/batch/delete", {
            data,
        });
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}
/**
 *
 *  移动成员
 * */
export async function team_merber_move(data): Promise<any> {
    try {
        const res = await http.put("/api/v1/team/member/batch/move", data);
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}
/**
 *
 * 根据成员id来修改成员的信息
 *
 * */
export async function team_member_idput(data): Promise<any> {
    try {
        const res = await http.put(`/api/v1/team/member/${data.id}/admin`, data);
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}

/**
 *
 * 修改成员的状态
 *
 * */
export async function team_meber_batchstaus(data): Promise<any> {
    try {
        const res = await http.put(`/api/v1/team/member/batch/update`, data);
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}
/**
 *
 * 审核成员 
 *
 * */
export async function team_merber_idreview(data): Promise<any> {
    try {
        const res = await http.put(`/api/v1/team/member/${data.id}/review`, data);
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}
/**
 *
 *  创建部门
 * */
export async function team_department(params): Promise<any> {
    try {
        const res = await http.post("/api/v1/team/department", params);
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}

/**
 *
 * 通过团队id获取全部部门信息
 * */
export async function team_department_findid(params): Promise<any> {
    try {
        const res = await http.get("/api/v1/team/department", { params });
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}
/**
 *
 * 根据部门id找到所属部门的信息
 * */
export async function team_department_iddele(data): Promise<any> {
    const query = `
        {department_detail(department_id:${data.department_id}){ id name team_name total members { member_name member_phone state } } }`;
    try {
        const res = await http.post(
            `/api/v1/graphql/`,
            ` ${query}`
            ,
            {
                headers: {
                    "Content-type": "application/graphql",
                },
            });
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}

/**
 *
 * 根据部门id来删除这个部门
 * */
export async function team_department_iddelete(data): Promise<any> {
    try {
        const res = await http.delete(`/api/v1/team/department`, { data });
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}

/**
 *
 * 根据团队id来修改部门的信息
 *
 * */
export async function team_department_idput(data): Promise<any> {
    try {
        const res = await http.put(`/api/v1/team/department/${data.id}`, data);
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}
/**
 *
 * 邀请链接
 *
 * */
export async function team_invite(data): Promise<any> {
    try {
        const res = await http.post(`/api/v1/team/invite`, data);
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}

export const departmentFindMember = async (params: {
    team_id: number;
    department_id: number;
}): Promise<any> => {
    try {
        const res = await http.get("/api/v1/team/member/search", {
            params: params,
        });
        return res.data.data.item;
    } catch (err) {
        return err;
    }
};
