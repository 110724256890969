import { IOrigin } from "@/stores/mixCutDetailStore/type";
import useMaterialPlayInfo from "./useMaterialPlayInfo";
import mixCutDetailStore from "@/stores/mixCutDetailStore";
import useMixCutDetailInit from "./useMixCutDetailInit";
import usePreviewConfig from "../preview/usePreview";
import usePreviewInfo from "./usePreviewInfo";
import { duration } from "moment";
// import previewConfing from "@/stores/previewStore";

export interface IUseJsonToRenderObj {
  handletoOriginJsonRender: (data: IOrigin) => void;
}

const useJsonToRenderObj = (): IUseJsonToRenderObj => {
  const { toInitRenderDataSetPreviewData } = usePreviewConfig();
  const { initTransitionGroup, initGlobleCaptionGroup } = useMixCutDetailInit();
  const { toIdsSetPlayInfo } = useMaterialPlayInfo();
  const { calcPreviewInfo } = usePreviewInfo();
  const handletoOriginJsonRender = (data: IOrigin) => {
    mixCutDetailStore.setProjectMaterialsInfo(data._materials);
    const newMaterialInfoKeys = Object.keys(data._materials || {});
    toIdsSetPlayInfo(newMaterialInfoKeys);
    mixCutDetailStore.setProjectSences(
      data.scenes.map((item) => {
        return {
          name: item.name,
          setting: item.setting,
          materials: item.materials,
        };
      }),
    );
    if (data?.global_settings?.enabled) {
      mixCutDetailStore.setProTitle(data?.global_settings?.titles);
    } else {
      const titles = data?.scenes.map((item) => item.title);
      mixCutDetailStore.setProTitle(titles);
    }
    mixCutDetailStore.setProjectCover(
      (data?.global_settings?.cover?.images || []).map((item) => item),
    );
    mixCutDetailStore.setProjectBgAudio(
      data?.global_settings?.background?.audio,
    );
    mixCutDetailStore.setProjectBgColor(
      data?.global_settings?.background?.color,
    );
    mixCutDetailStore.setProjectBgImages(
      data?.global_settings?.background?.images,
    );
    mixCutDetailStore.setProjectBgAudioVolum(
      data?.global_settings?.background?.audio_volume || 50,
    );
    if (data.global_settings.enabled) {
      initTransitionGroup();
      mixCutDetailStore.setProjectIsGolbal(true);
      mixCutDetailStore.setProjectGlobalCaptions(data.global_settings.captions);
      mixCutDetailStore.setProjectGlobalTransition({
        duration: data.global_settings.transition.duration,
        effects: data.global_settings.transition.effects,
      });
    } else {
      initGlobleCaptionGroup();
      mixCutDetailStore.setProjectIsGolbal(false);
      mixCutDetailStore.setProjectCaption(
        data.scenes.map((item) => {
          return item.caption;
        }),
      );
      mixCutDetailStore.setTransition(data.transition);
    }
    toInitRenderDataSetPreviewData(data);
    calcPreviewInfo(data);
  };

  return {
    handletoOriginJsonRender,
  };
};

export default useJsonToRenderObj;
