import {
  CamelCasedProperties,
  SnakeCasedProperties,
} from "./tsUtils/typeTranslate";

function snakeToCamelCase(str: string): string {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
}

export const convertKeysToCamelCase = <T extends Object>(
  obj: T,
): CamelCasedProperties<T> => {
  return Object.keys(obj).reduce((acc, key) => {
    const camelCaseKey = snakeToCamelCase(key);
    const value = obj[key as keyof T];

    (acc as any)[camelCaseKey as keyof CamelCasedProperties<T>] =
      typeof value === "object" && value !== null && !Array.isArray(value)
        ? convertKeysToCamelCase(value)
        : value;

    return acc;
  }, {} as CamelCasedProperties<T>);
};

function camelToSnakeCase(str: string): string {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export const convertKeysToSnakeCase = <T extends Object>(
  obj: T,
): SnakeCasedProperties<T> => {
  return Object.keys(obj).reduce((acc, key) => {
    const snakeCaseKey = camelToSnakeCase(key);
    const value = obj[key as keyof T];

    (acc as any)[snakeCaseKey] =
      typeof value === "object" && value !== null && !Array.isArray(value)
        ? convertKeysToSnakeCase(value)
        : value;

    return acc;
  }, {} as SnakeCasedProperties<T>);
};
