import preivewInfoStore, {
  IError,
  ITimer,
} from "@/stores/mixCutDetailStore/previewInfo";
import { IOrigin } from "@/stores/mixCutDetailStore/type";
import { combSenceMaterials } from "@/utils/perm";

interface IUsePreviewInfoReturn {
  handleTimerChange: (timer: ITimer) => void;
  handleMaxCount: (count: number) => void;
  handleErrorChange: (error: IError[]) => void;
  timer: ITimer;
  maxCount: number;
  error: IError[];
  calcPreviewInfo: (data: IOrigin) => void;
  toIndexGetError: (index: number) => string;
}

const usePreviewInfo = (): IUsePreviewInfoReturn => {
  const handleTimerChange = (timer: ITimer) => {
    return preivewInfoStore.setTimer(timer);
  };

  const handleMaxCount = (count: number) => {
    return preivewInfoStore.setMaxCount(count);
  };
  const handleErrorChange = (errors: IError[]) => {
    return preivewInfoStore.setError(errors);
  };

  const toIndexGetError = (index: number) => {
    return (
      (preivewInfoStore.getError() || []).find((item) => item.scene === index)
        ?.message || null
    );
  };

  const calcPreviewInfo = (data: IOrigin) => {
    console.log("comb", combSenceMaterials(data));
    const { maxCount, minDuration, maxDuration, errors } =
      combSenceMaterials(data);

    handleMaxCount(maxCount || 0);
    handleErrorChange(errors || []);
    handleTimerChange(
      {
        minDuration,
        maxDuration,
      } || null,
    );
  };

  return {
    handleMaxCount,
    handleTimerChange,
    handleErrorChange,
    timer: preivewInfoStore.getTimer(),
    maxCount: preivewInfoStore.getMaxCount(),
    error: preivewInfoStore.getError(),
    calcPreviewInfo,
    toIndexGetError,
  };
};

export default usePreviewInfo;
