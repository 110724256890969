import creationStores from "./creationScriptStore";
import home from "./counterStore";
import spaceStroe from "./spaceStore/index";
import login from "./login";
import invite from "./invite";
import team from "./team";
import videoallyis from "./videoallyis";
import teamover from "./teamover";
import userStore from "./userStore";
export default {
  creationStores,
  home,
  ...spaceStroe,
  login,
  team,
  invite,
  teamover,
  videoallyis,
  userStore,
};
