import http from "@/utils/http";
export async function getAataIntegration(): Promise<any> {
  const query = `  
{
  workbench_data_integration{
    compose{
      total_composed
      month_composed
    }
    publish{
      published
      month_published
    }
    benefits{
      benefit_type
      benefit_value
    }
    collection{
      script_num
      session_num
      job_num
    }
  }
}
`;

  try {
    const res = await http.post(`/api/v1/graphql/`, {
      query,
    });
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

export async function wechatQrcode(): Promise<any> {
  try {
    const res = await http.get("/api/v1/user/wechat_qrcode");
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}
