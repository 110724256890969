import http from "@/utils/http";

export interface IDepartment {
  id: string;
  member_phone: string;
  name: string;
  total: number;
  updated_at: number;
}

export interface IDepartmentMember {
  department_name: string;
  id: string;
  member_name: string;
  member_phone: string;
  member_uuid: string;
  role_id: string;
  state: number;
  department_id: number;
}
// 通过teamId获取下面所有部门
export const toTeamIdGetAllDepartment = async (
  params,
): Promise<IDepartment[]> => {
  try {
    const res = await http.get("/api/v1/team/department", {
      params: { ...params, page: 1, pageSize: 100 },
    });
    return res.data.items;
  } catch (err) {
    console.log(err);
  }
};
// 通过团队获取部门所有员工
export const toDepartmentFindMember = async (params: {
  state: 3;
}): Promise<IDepartmentMember[]> => {
  try {
    const res = await http.get("/api/v1/team/member/search", { params });
    return res.data.info;
  } catch (err) {
    console.log(err);
  }
};
