import { makeAutoObservable } from "mobx";

export interface IBackground {
  color: string;
  image_url: string;
}

class ClipId {
  private clipId: number;

  constructor() {
    makeAutoObservable(this);
  }

  public getClipId() {
    return this.clipId;
  }

  public setClipId(clipId: number) {
    this.clipId = clipId;
  }
}

const clipIdStore = new ClipId();
export default clipIdStore;
