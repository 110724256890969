import { makeAutoObservable } from "mobx";
import axios, { AxiosProgressEvent, CancelTokenSource } from "axios";
import { v4 as uuid } from "uuid";
import {
  IAliOssToken,
  IAliyunRequest,
  getUploadToken,
} from "@/services/fileUpload/fileUpload";
import http from "@/utils/http";

import { CardTypeEnum } from "@/pages/CreationSpace/components/MaterialCard/MaterialCard";
import {
  IMaterialDetail,
  getSimpleMedia,
} from "@/services/createSpace/graphQ/getSimpleMedia";
import {
  fileToUrl,
  getFileType,
} from "@/components/business/MaterialFileModal/UploadFileNode/useParseFile";
import parseMovieGetCoverAndTime from "@/components/business/MaterialFileModal/UploadFileNode/parseMovieGetCoverAndTime";
import getMp3Duration from "@/components/business/MaterialFileModal/UploadFileNode/getMp3Duration";
import { UploadNodeState } from "@/components/business/MaterialFileModal/UploadFileNode/UploadFileNode";
// import { setUploadGroupItem } from "@/localStorage/uploadStorage";

const getMaterialState = async (id: string) => {
  const result = await http.get(`/api/v1/space/material/${id}`);
  return (result as any).data.material;
};

const handleUploadFile = async (
  file: File,
  data: IAliyunRequest,
  onProgress: (progress: number) => void,
  cancelToken: CancelTokenSource,
  id: string,
) => {
  const aliyunToken = await getUploadToken({
    filename: data.filename,
    floderId: data.floderId,
    projectId: data.projectId,
    size: file.size,
  });
  return uploadFile(aliyunToken, file, onProgress, cancelToken, id);
};
const uploadFile = async (
  token: IAliOssToken,
  file: File,
  onProgress: (progress: number) => void,
  cancelToken: CancelTokenSource,
  id: string,
): Promise<void> => {
  const newFormData = new FormData();
  newFormData.append("key", `${token.dir}${uuid()}-${file.name}`);
  newFormData.append("callback", token.callback);
  newFormData.append("OSSAccessKeyId", token.accessid);
  newFormData.append("policy", token.policy);
  newFormData.append("Signature", token.signature);
  newFormData.append("expire", token.expire);
  newFormData.append("success_action_status", "200");
  newFormData.append("Content-Disposition", `attachment`);
  newFormData.append("file", file);
  try {
    const response = await axios.post(token.host, newFormData, {
      timeout: 100000000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (event: AxiosProgressEvent) => {
        if (event.total) {
          const percentComplete = (event.loaded / event.total) * 100;
          onProgress(percentComplete);
        }
      },
      cancelToken: cancelToken.token,
    });
    if (response.status === 200) {
      if (response.data.data.method !== "media") {
        return;
      }
      const material_id = response.data.data.id;
      const timer = setInterval(async () => {
        const parseData = await getMaterialState(material_id);
        const status = parseData?.status || 0;
        const task = fileUploadStore.getTaskById(id);
        if (status === 2) {
          task.status = "parseLoading";
        }
        if (status === 3) {
          clearInterval(timer);
          task.status = "parseError";
        }
        if (status === 4) {
          if (task) {
            task.material = parseData;
            try {
              task.detailMaterial = await getSimpleMedia(parseData.id);
              task.img = task.detailMaterial.play_info.cover_url;
            } catch (e) {
              console.log(e);
            } finally {
              task.status = "success";
            }
          }
          clearInterval(timer);
        }
      }, 3000);
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Upload canceled");
    } else {
      console.error("Upload failed:", error);
    }
  }
};

export interface UploadGroupItem {
  id: string;
  error: string;
  name: string;
  file: File;
  folder_id: number;
  project_id: number;
  status: UploadNodeState;
  progress: number;
  cancel: CancelTokenSource;
  type?: string;
  img?: string;
  duration?: number;
  material?: any;
  detailMaterial?: IMaterialDetail;
  size: number;
}

export interface UploadGroup {
  id: string;
  update_at: number;
  taskList: UploadGroupItem[];
}

class FileUploadStore {
  uploadTasks: Array<UploadGroup> = [];
  queue: string[] = [];
  maxConcurrentUploads = 3;
  activeUploads = 0;

  constructor() {
    makeAutoObservable(this);
  }

  async parseFile(file: File) {
    const fileType = getFileType(file);
    let img = "";
    let duration = 0;

    switch (fileType) {
      case CardTypeEnum.MOVIE: {
        console.log("这是电影走这儿");
        try {
          const { img: movieImg, duration: movieDuration } =
            await parseMovieGetCoverAndTime(file);
          console.log(duration);
          img = movieImg;
          duration = movieDuration;
        } catch (e) {
          console.log(e);
        }

        break;
      }
      case CardTypeEnum.MUSIC: {
        duration = await getMp3Duration(file);
        img = "path/to/default/mp3/cover.jpg";
        break;
      }
      case CardTypeEnum.PICTURE: {
        img = fileToUrl(file);
        break;
      }
      default:
        break;
    }

    return { fileType, img, duration };
  }

  addUploadTasks(
    files: File[],
    folder_id: number,
    project_id: number,
    groupName: string,
    batchId: string,
  ) {
    const tasks = files.map((file) => {
      const taskId = uuid();
      const source = axios.CancelToken.source();
      return this.parseFile(file).then(({ fileType, img, duration }) => ({
        name: file.name,
        id: taskId,
        file,
        folder_id,
        project_id,
        status: "queued" as UploadNodeState,
        progress: 0,
        cancel: source,
        type: fileType,
        img,
        duration,
        error: "",
        size: file.size,
      }));
    });

    Promise.all(tasks).then((resolvedTasks) => {
      this.uploadTasks.push({
        id: batchId,
        taskList: resolvedTasks,
        update_at: Date.now(),
      });
      resolvedTasks.forEach((task) => {
        this.queue.push(task.id);
      });
      // setUploadGroupItem(this.uploadTasks);
      this.processQueue();
    });
  }

  getTaskById(taskId: string) {
    for (const batch of this.uploadTasks) {
      const task = batch.taskList.find((t) => t.id === taskId);
      if (task) {
        return task;
      }
    }
    return null;
  }

  processQueue() {
    if (
      this.activeUploads < this.maxConcurrentUploads &&
      this.queue.length > 0
    ) {
      const taskId = this.queue.shift();
      if (taskId) {
        this.startUpload(taskId);
      }
    }
  }

  async startUpload(taskId: string) {
    const task = this.getTaskById(taskId);
    if (!task) return;

    task.status = "uploading";
    this.activeUploads++;
    try {
      await handleUploadFile(
        task.file,
        {
          filename: task.file.name,
          floderId: task.folder_id,
          projectId: task.project_id,
          size: task.size,
        },
        (progress) => {
          this.updateTaskProgress(taskId, Number(progress.toFixed(1)));
        },
        task.cancel,
        task.id,
      );
    } catch (error) {
      task.status = "error";
      task.error = error;
    } finally {
      this.activeUploads--;
      this.processQueue();
    }
  }

  updateTaskProgress(taskId: string, progress: number) {
    const task = this.getTaskById(taskId);
    if (task) {
      task.progress = progress;
    }
  }

  cancelUploadTask(taskId: string) {
    for (const batch of this.uploadTasks) {
      const taskIndex = batch.taskList.findIndex((t) => t.id === taskId);
      if (taskIndex !== -1) {
        const task = batch.taskList[taskIndex];
        if (task.cancel) {
          task.cancel.cancel();
        }
        batch.taskList.splice(taskIndex, 1);
        if (batch.taskList.length === 0) {
          const batchIndex = this.uploadTasks.findIndex(
            (b) => b.id === batch.id,
          );
          if (batchIndex !== -1) {
            this.uploadTasks.splice(batchIndex, 1);
          }
        }
        break;
      }
    }
  }

  clearExamine = () => {
    const newTasks = [...this.uploadTasks];
    this.uploadTasks = newTasks.map((batch) => ({
      ...batch,
      taskList: batch.taskList.map((task) => ({
        ...task,
        isExamine: true,
      })),
    }));
  };
}

export const fileUploadStore = new FileUploadStore();
