import { action, makeAutoObservable, observable } from "mobx";
import {
  team_membet_uuidget,
  team_department_idput,
  team_department_iddelete,
  team_department_iddele,
  team_department_findid,
  team_department,
  team_merber_move,
  team_member_delete,
  team_use,
  team_id_use,
  team_role,
  team_meber,
  team_merber_search,
  team_invite,
  team_member_idput,
  team_meber_batchstaus,
  team_detailserach,
  team_merber_idreview,
} from "@/services/teme";
import { Message } from "@arco-design/web-react";
class Team {
  token = null;
  user_info = {};
  request_id = {};
  team_name = null; //团队名称
  team_id = 2; //团队id
  depedata = null;
  Treedepedata = [
    // 成员 左侧 three  data
  ];
  meberdata = [
    // 成员 data数据
  ];
  activedata = [
    // 成员 data active 数据
  ];

  topmeberdata = {
    index: null,
    role_id: null,
    department_id: null,
    state: null,
    page: 1,
    page_size: 10
  }; // 头部 筛选 数据
  merbersum = 0
  selectmeberactive = [
    // 多选 选中的成员
  ];
  selectmeberactiverole = null;
  selectmeberactivestate = false;
  depselectdata = {
    // 部门筛选
    name: "",
    page: 1,
    page_size: 10,
  };
  datasum = 0
  ttdepedata = []; //    data渲染 数据  部门
  ttselectdepe = []; //  选择的data   部门
  ttselectdepeactive = false; // 选择的 depe  是否 可以删除

  isSuccess = false;
  isLoading = false;
  depeactive = false
  depedataon = false
  DataActiveDep_id = null;
  constructor() {
    makeAutoObservable(this);
  }
  role_idsetlect(role) {
    console.log(role);
    let letout = 0
    let opout = 0
    if (!role) {
      this.selectmeberactiverole = false
    }
    else {
      role?.forEach((item) => {
        if (item?.role_id.includes("1")) {
          letout = letout + 1
          this.selectmeberactiverole = true
        }
        if (item?.state == 2 || item?.state == 1) {
          opout = opout + 1
          this.selectmeberactivestate = true
        }
      })
      if (letout == 0) {
        this.selectmeberactiverole = false
      }
      if (opout == 0) {
        this.selectmeberactivestate = false
      }
    }

  }



  handeSelctactiveDep(depeid) {
    this.DataActiveDep_id = depeid
  }
  handeMovemeber() {
    if (this.DataActiveDep_id) {
      this.team_merber_search_to({
        department_id: this.DataActiveDep_id
      });
    }

  }
  handeselecttdata(ac, keyid?) {
    if (ac == "角色") {
      if (keyid == 1) {
        this.topmeberdata.role_id = null;
      } else {
        this.topmeberdata.role_id = String(keyid);
      }
    } else if (ac == "部门") {
      this.topmeberdata.department_id = keyid;
      this.topmeberdata.page = 1;
      this.topmeberdata.page_size = 10;
    } else if (ac == "状态") {
      if (keyid == 0) {
        this.topmeberdata.state = null;
      } else {
        this.topmeberdata.state = keyid;
      }
    } else if (ac == "index") {
      this.topmeberdata.index = keyid;
    } else if (ac == "data") {
      this.topmeberdata = {
        index: null,
        role_id: null,
        department_id: null,
        state: null,
        ...this.topmeberdata
      };
    }
    else if (ac == "depeman") {
      this.topmeberdata.department_id = null;
    }
    this.team_merber_search_to(this.topmeberdata);
  }
  handeselect(page, size) {
    this.topmeberdata = {
      ...this.topmeberdata,
      page: page,
      page_size: size
    }
    this.team_merber_search_to(this.topmeberdata);
  }
  oponDepe() {
    this.depedataon = true
    this.depeactive = !this.depeactive
  }

  /***
   * 修改状态
   */
  someMethod(data) {
    const { token, user_info, request_id } = data;
    this.token = token;
    this.user_info = user_info;
    this.request_id = request_id;
  }


  selectset(data) {
    // 部门选择筛选 删除部门判断所选部门中 人数
    let arr = true;
    const dataId = [];
    data.map((item) => {
      if (item.total != 0) {
        arr = false;
      }
      dataId.push(item.id);
    });
    this.ttselectdepe = dataId;
    this.ttselectdepeactive = arr;
  }

  adddepartment() {
    // 数据转换  three
    const list = [];
    this.ttdepedata.forEach((item) => {
      if (list.findIndex((v) => v.title == item.name) !== -1) {
        return;
      } else {
        list.push({ title: item.name, key: item.key, total: item.total, uuid: item.member_uuid });
      }
    });
    this.Treedepedata = list;
  }

  selectdepartment(e) {
    let listdata = [];
    if (e != "dep") {
      listdata = this.meberdata.filter((item) => {
        return item.department == e[0];
      });
      this.activedata = listdata;
    } else {
      this.activedata = this.meberdata;
    }
  }

  @action
  updateTtdepedata(newData) {
    const data = newData;
    data.map((item) => {
      item.key = item.id;
      const date = new Date(item.updated_at * 1000);
      item.updated_at = date.toLocaleString();
      if (!item.total) {
        item.total = 0;
      }
      return item
    });
    this.ttdepedata = data;
    this.adddepartment();
  }

  @action
  updatemeberdata(newData) {
    if (!newData) return;
    const data = newData;
    data.map((item) => {
      item.key = item.id;
    });
    this.meberdata = data;
  }

  updataselectdata(name?: string, current?: number, pagesize?: number) {
    let newname
    if (name == null && name != '') {
      newname = this.depselectdata.name
    }
    else {
      newname = name
    }
    this.team_department_findid_to(newname, current || this.depselectdata.page, pagesize || this.depselectdata.page_size);
    this.depselectdata = {
      name: newname,
      page: current || 1,
      page_size: pagesize || 10,
    }
  }

  /***
   * 传入团队名称  创建团队   { team_id:4,14}
   */
  team_use_to(datalist: string) {
    try {
      const data = {
        name: datalist,
        main_package_id: 1,
        owner_uuid: "529714523087631148",
      };
      team_use(data).then((res) => {
        if (res?.code == 0) {
          console.log(res.data);
        } else {
          // Message.info(res.message);
        }
      });
    } catch (error) {
      console.error("Login failed:", error);
    } finally {
    }
    return false;
  }
  /***
   *  id serch merber
   */
  team_id_use_to(datalist) {
    try {
      team_id_use({
        id: 4,
      }).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          this.isSuccess = true;
          this.someMethod(res.data);
          Message.success("成功");
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error("Login failed:", error);
    } finally {
    }
    return false;
  }
  /***
   *  查询 当前团队
   */
  async team_membet_uuidget_to() {
    try {
      return await team_detailserach().then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          // Message.info("团队id获取成功");
          this.isSuccess = true;
          this.team_name = res.data.item.name; // 当前团队名称
          this.someMethod(res.data);
          return res.data.item.id;
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error("Login failed:", error);
    } finally {
    }
    return false;
  }
  /***
   * 列出 所有角色
   */
  teme_role_to(datalist) {
    try {
      team_role(datalist).then((res) => {
        if (res?.code == 0) {
          this.isSuccess = true;
          this.someMethod(res.data);
          // Message.success("成功");
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  /***
   * 创建成员
   */
  team_meber_to(datalist) {
    try {
      return team_meber(datalist).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          this.isSuccess = true
          // Message.success("成功");
        } else {
          Message.info(res);
        }
      })
    }
    catch (error) {
      console.error(error);
    }
  }

  /***
   * 通过成员id来修改成员信息
   */
  team_member_idput_to(datalist) {
    try {
      return team_member_idput(datalist).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          this.isSuccess = true;
          this.team_merber_search_to(this.topmeberdata);
          Message.success("详情修改成功");
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  /***
   * 查找成员
   */
  team_merber_search_to(data?) {
    try {
      team_merber_search(data && data).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          if (JSON.stringify(res.data) == "{}") {
            this.meberdata = [];
            return;
          }
          this.isSuccess = true;
          this.updatemeberdata(res.data.info);
          this.merbersum = res.data.sum
          // Message.success("成功");
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  /***
   * 查找成员 并返回成员 
   */
  team_merber_search_topo(data?) {
    try {
      return team_merber_search(data && data).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          if (JSON.stringify(res.data) == "{}") {
            return {
              sum: 0,
              data: []
            }
          }
          return {
            sum: res.data?.sum,
            data: res.data?.info || []
          }
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  /***
   * 删除成员
   */
  team_member_delete_to(formrefSmlong) {
    //
    try {
      team_member_delete(formrefSmlong).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          Message.success("成员删除成功");
          this.team_merber_search_to(this.topmeberdata);
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  /***
   * 批量修改成员的状态
   */
  team_meber_batchstaus_to(formrefSmlong) {
    //
    try {
      team_meber_batchstaus(formrefSmlong).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          // Message.success("成员状态修改成功");
          this.team_merber_search_to(this.topmeberdata);
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  /***
   * 审核成员
  */
  team_merber_idreview_to(formrefSmlong) {
    //
    try {
      team_merber_idreview(formrefSmlong).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          // Message.success("成员状态修改成功");
          this.team_merber_search_to(this.topmeberdata);
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }


  /***
   * 移动成员
   */
  team_merber_move_to(FormreGist) {
    try {
      team_merber_move(FormreGist).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          // Message.success("成员移动成功");
          this.handeMovemeber()
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  /***
   * 创建部门  { id=7 }   数字技术组  id7
   */
  async team_department_to(FormreGist) {
    try {
      await team_department({
        name: FormreGist,
        parent_id: 0,
        creator: "529714523087631148",
      }).then((res) => {
        console.log(res.code);
        if (res?.code == 0 && res?.message == "OK") {
          Message.success("部门创建成功");
          this.team_department_findid_to('');
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  /***
   *  获取该团队下全部 部门
   */
  async team_department_findid_to(newname?, newpage?, newsize?) {
    const { name, page, page_size } = this.depselectdata;
    try {
      return await team_department_findid({
        name: newname != undefined ? newname : name,
        page: newpage ? newpage : page,
        page_size: newsize ? newsize : page_size,
      }).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          // Message.success("部门获取成功");
          if (JSON.stringify(res.data) == "{}") {
            this.ttdepedata = [];
            return;
          }
          this.datasum = res.data.sum || 0
          this.updateTtdepedata(res.data.items);
          return res.data.items;
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  /***
   *  根据部门id找到所属部门的信息
   */
  team_department_iddele_to(FormreGist) {
    try {
      return team_department_iddele({
        department_id: FormreGist,
      }).then((res) => {
        console.log(res, res.data);
        if (res.data.department_detail && !res?.errors) {
          // Message.success("部门详情获取成功");
          return res.data.department_detail;
        } else {
          Message.info(res.errors[0].message);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  /***
   *  删除部门
   */
  team_department_iddelete_to(data?) {
    try {
      team_department_iddelete({
        department_ids: data ? [data] : this.ttselectdepe,
      }).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          this.team_department_findid_to();
          Message.success("部门删除成功");
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  /***
   *  修改部门
   */
  team_department_idput_to(FormreGist) {
    try {
      team_department_idput({
        id: FormreGist.id,
        name: FormreGist.name,
      }).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          this.team_department_findid_to();
          Message.success("部门修改成功");
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  /***
   *  邀请链接
   */
  async team_invite_to(data) {
    try {
      await team_invite(data).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          if (JSON.stringify(res.data) == "{}") {
            return;
          }
          navigator.clipboard.writeText(res.data);
          Message.success("链接复制成功");
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
}

const teme = new Team();
export default teme;
