/* eslint-disable no-constant-condition */
import axios from "axios";
import http from "@/utils/http";
import { getTokenToLocalStorage } from "@/utils/token";
/**
 * 解析视频
 * **/
export async function script_parse(data): Promise<any> {
  try {
    const res = await http.post("/api/v1/script/parse", data);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
/**
 * 上传视频
 * **/
export async function script_parse_upload(data): Promise<any> {
  try {
    const res = await http.post("/api/v1/script/parse/upload", data);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
/**
 * 视频解析记录
 * **/
export async function script_parse_record(params): Promise<any> {
  console.log("111111111111111");

  try {
    const res = await http.get("/api/v1/script/parse/record", { params });
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
/**
 * 删除视频解析记录
 * **/
export async function script_parse_rdid(params): Promise<any> {
  try {
    const res = await http.delete(`/api/v1/script/parse/${params}`);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
/**
 * 获取视频解析详情
 * **/
export async function script_parse_rdidget(params): Promise<any> {
  try {
    const res = await http.get(`/api/v1/script/parse/${params}`);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
/**
 * 获取解析进度
 * **/
export async function script_parse_progress(params): Promise<any> {
  try {
    const res = await http.get(`/api/v1/script/parse/progress/${params.id}`);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
/**
 * 重新解析
 * **/
export async function script_parse_retry(data): Promise<any> {
  try {
    const res = await http.post(`/api/v1/script/parse/retry`, data);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
/**
 * 根据团队名称-创建团队-得到团队id
 * **/
export async function team_use(data): Promise<any> {
  try {
    const res = await axios.post("/api/v1/script/parse/record/extract", data);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

export interface IGetEditingPreview {
  project_id: number;
  session_id: number;
  data: string;
}

export type IGetEditingPreviewReturnType = {
  duration: number;
  timeline: string;
  total: 1;
};

// 配置预览
export async function getEditingPreview(
  data: IGetEditingPreview,
): Promise<IGetEditingPreviewReturnType> {
  try {
    const res = await http.post(`/api/v1/editing/preview`, data);
    if (res) {
      return res.data;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

export async function* readStreamDataIncrementally(response) {
  const reader = response.body.getReader();
  const decoder = new TextDecoder("utf-8");
  let buffer = "";

  while (true) {
    const { value, done } = await reader.read();
    if (done) break;
    buffer += decoder.decode(value, { stream: true });
    const lines = buffer.split("\n");

    // 确保处理完每一行后，更新 buffer
    buffer = lines.pop() || "";

    for (const line of lines) {
      if (line.startsWith("data:")) {
        try {
          const jsonData = JSON.parse(line.slice(5).trim());
          const status = jsonData.Response?.Status?.status;
          const total = jsonData.Response?.Status?.total;

          yield jsonData; // 使用 yield 返回每一段解析成功的数据

          if (status === 1 || status === 2 || total === 0) {
            console.log("检测到停止条件，立即结束");
            return;
          }

          if (total === 1 && jsonData.Response?.Timeline) {
            console.log("total 为 1 且含有 Timeline 数据，停止并返回");
            return;
          }
        } catch (error) {
          console.error("数据解析失败:", error, "出错的数据:", line);
        }
      } else {
        console.log("跳过非 data 行:", line);
      }
    }
  }
}

const toAspectRadioGetWidthAndHeight = (aspectRadio: string) => {
  const obj = {
    "9:16": { Width: 360, Height: 640 },
    "16:9": { Width: 640, Height: 360 },
    "3:4": { Width: 360, Height: 480 },
    "4:3": { Width: 480, Height: 360 },
    "1:1": { Width: 480, Height: 480 },
  };
  return obj[aspectRadio];
};

// 智能混剪
export async function* getEditingTimelineId(data: {
  project_id: number;
  session_id: number;
  aspectRadio: string;
  data: string;
}): AsyncGenerator<any> {
  const token = getTokenToLocalStorage();
  const res = await fetch(`/api/v1/editing/timeline`, {
    method: "POST",
    headers: {
      Token: `${token}`,
      "content-type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      ...data,
      max_rate: 70,
      result_limit: 1000,
      preview_config: JSON.stringify({
        From: "websdk",
        AspectRatio: data.aspectRadio,
        FECanvas: {
          ...toAspectRadioGetWidthAndHeight(data.aspectRadio),
        },
      }),
    }),
  });
  console.log("这是我走的接口,我要走这里");

  // 使用改进后的 `readStreamDataIncrementally` 函数逐段返回数据
  for await (const jsonData of readStreamDataIncrementally(res)) {
    yield jsonData; // 逐段返回每一段解析的 event stream 数据
  }
}

export interface IGetEditingResultsData {
  medias: string[];
  rate: number;
  timeline: string;
  status: 0 | 1 | 2 | 3;
  id: number;
}

export interface IGetEditingResultMsg {
  flow_id: number;
  origin_data: string;
  session_id: number;
  status: string;
  total: string;
}

export interface IGetEditingResultsdataReturn {
  data: IGetEditingResultsData[];
  msg: IGetEditingResultMsg;
}

export interface IGetEditingRequest {
  session_id: number;
  page: -1;
  page_size: -1;
}

// export const getEditingResults = async (
//   data: IGetEditingRequest,
// ): Promise<IGetEditingResultsdataReturn> => {
//   const token = getTokenToLocalStorage();
//   try {
//     const response = await fetch(`/api/v1/editing/results`, {
//       method: "POST",
//       headers: {
//         Token: `${token}`,
//         "content-type": "application/json;charset=UTF-8",
//       },
//       body: JSON.stringify(data),
//     });
//     const result = await readAllStreamData(response);
//     const timelines = result
//       .filter((item) => item.Response?.Timeline?.timeline)
//       .map((item) => item.Response?.Timeline) as IGetEditingResultsData[];
//     const msg = result[0].Response.Status;
//     const status = result[0]?.Response?.Status?.status;
//     if (status === 2 || status === 3) {
//       return { data: timelines, msg: msg };
//     } else if (status === 1) {
//       console.log("状态为 1，继续轮询...");
//       await new Promise((resolve) => setTimeout(resolve, 5000));
//       return getEditingResults(data);
//     }
//   } catch (e) {
//     console.error("请求出错:", e);
//     return null;
//   }
// };

// export interface IVideoComponseSubmit {
//   flow_id: number;
//   session_id: number;
//   time_line: string;
//   project_id: number;
//   parent_id: number;
//   bitrate: number;
//   fps: number;
//   proportion: string;
//   rate: string;
// }

// export const videoComponseSubmit = async (data: IVideoComponseSubmit) => {
//   const result = http.post(`/api/v1/space/session/submit`, {
//     ...data,
//   });
//   return result;
// };

export interface ICompositionTaskInitRequest {
  session_id: number;
  folder_id: number;
  aspect_ratio: string;
  resolution: string;
  bitrate: number;
  fps: number;
  project_id: number;
}

//初始化任务
export const compositionTaskInit = async (
  data: ICompositionTaskInitRequest,
): Promise<string> => {
  const result = await http.post(`/api/v1/space/session/task/init`, {
    ...data,
  });
  return result.data.task_no;
};

export interface ICompositionCreateSubTaskRequest {
  task_no: string;
  timeline_id: number;
  timeline: string;
  title: string;
}

//新建子任务任务
export const compositionCreateSubTask = async (
  data: ICompositionCreateSubTaskRequest,
) => {
  await http.post(`/api/v1/space/session/task/submit`, {
    ...data,
  });
};

export const submitFinishedTask = async (data: { task_no: string }) => {
  await http.post(`/api/v1/space/session/task/complete`, {
    ...data,
  });
};
