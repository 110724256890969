import { getSimpleMedia } from "@/services/createSpace/graphQ/getSimpleMedia";
import mixCutDetailStore from "@/stores/mixCutDetailStore";
import { IMaterialPlayInfoItem } from "@/stores/mixCutDetailStore/type";
import { toJS } from "mobx";

export interface IUseMaterialPlayInfo {
  materialPlayInfo: Record<string, IMaterialPlayInfoItem>;
  toIdDeleteMaterailPlayInfo: (id: string) => void;
  handleMaterialItemAdd: (id: string, playInfo: IMaterialPlayInfoItem) => void;
  handleMaterialItemRemove: (id: string) => void;
  toIdFindMaterialItem: (id: string) => IMaterialPlayInfoItem;
  toIdSetPlayInfo: (id: string | number) => Promise<void>;
  toIdsSetPlayInfo: (ids: string[] | number[]) => Promise<void>;
}

const useMaterialPlayInfo = (): IUseMaterialPlayInfo => {
  const handleMaterialItemAdd = (
    id: string,
    play_info: IMaterialPlayInfoItem,
  ) => {
    const allMaterial = { ...mixCutDetailStore.getProjectMaterialsInfo() };
    allMaterial[id] = play_info;
    mixCutDetailStore.setProjectMaterialsInfo({ ...allMaterial });
  };

  const handleMaterialItemRemove = (id: string) => {
    const allMaterial = mixCutDetailStore.getProjectMaterialsInfo();
    delete allMaterial[id];
    mixCutDetailStore.setProjectMaterialsInfo({ ...allMaterial });
  };
  const toIdFindMaterialItem = (id: string) => {
    try {
      return mixCutDetailStore.getProjectMaterialsInfo()[id];
    } catch (e) {
      console.log(e);
    }
  };

  const toIdSetPlayInfo = async (id: string | number) => {
    const playInfo = await getSimpleMedia(Number(id));
    const allMaterial = mixCutDetailStore.getProjectMaterialsInfo();
    allMaterial[id] = {
      url: playInfo?.play_info?.play_url,
      cover: playInfo?.play_info?.cover_url,
    };
  };

  const toIdDeleteMaterailPlayInfo = (id: string) => {
    const newMaterailPlayInfo = {
      ...mixCutDetailStore.getProjectMaterialsInfo(),
    };
    delete newMaterailPlayInfo[id];
    mixCutDetailStore.setProjectMaterialsInfo(newMaterailPlayInfo);
  };

  const toIdsSetPlayInfo = async (ids: string[] | number[]) => {
    if (!ids || ids.length === 0) {
      return;
    }
    const newIds = ids.map((id) => Number(id));
    newIds.forEach(async (id) => {
      await toIdSetPlayInfo(id);
    });
  };
  return {
    materialPlayInfo: mixCutDetailStore.getProjectMaterialsInfo(),
    handleMaterialItemAdd,
    handleMaterialItemRemove,
    toIdFindMaterialItem,
    toIdSetPlayInfo,
    toIdsSetPlayInfo,
    toIdDeleteMaterailPlayInfo,
  };
};

export default useMaterialPlayInfo;
