import mixCutDetailStore from "@/stores/mixCutDetailStore";
import {
  ITransition,
  ITransitionItem,
} from "@/stores/mixCutDetailStore/type/transition";
import useMixCutDetailSave from "./useMixCutDetailSave";
import { toJS } from "mobx";

export interface ITransitionReturn {
  transition: ITransition;
  handleTransitionItemAdd: (key: string, data: ITransitionItem) => void;
  handleTransitionItemRemove: (key: string) => void;
  handleTransitionItemChange: (key: string, data: ITransitionItem) => void;
  handleTransitionItemEffectsRemove: (key: string, index: number) => void;
  handleTransitionItemIsGlobaltoTrue: (key: string) => void;
}

const useTransition = (): ITransitionReturn => {
  const { handleSaveMixCutDetailSave } = useMixCutDetailSave();
  const handleTransitionItemAdd = (key: string, data: ITransitionItem) => {
    const newTransition = {
      ...mixCutDetailStore.getTransition(),
      [key]: data,
    };
    mixCutDetailStore.setTransition(newTransition);
    handleSaveMixCutDetailSave();
  };

  const handleTransitionItemRemove = (key: string) => {
    delete mixCutDetailStore.getTransition()[key];
    mixCutDetailStore.setTransition({ ...mixCutDetailStore.getTransition() });
    handleSaveMixCutDetailSave();
  };

  const handleTransitionItemChange = (key: string, data: ITransitionItem) => {
    const newTransition = { ...mixCutDetailStore.getTransition() };
    newTransition[key] = data;
    mixCutDetailStore.setTransition(newTransition);
    if (data.is_global) {
      handleTransitionItemIsGlobaltoTrue(key);
    }
    handleSaveMixCutDetailSave();
  };

  const handleTransitionItemEffectsRemove = (key: string, index: number) => {
    const newMixCutDetailStroeTransitionItem =
      mixCutDetailStore.getTransition()[key];
    if (
      index >= 0 &&
      index < newMixCutDetailStroeTransitionItem.effects.length
    ) {
      newMixCutDetailStroeTransitionItem.effects.splice(index, 1);

      mixCutDetailStore.setTransition({
        ...mixCutDetailStore.getTransition(),
        [key]: newMixCutDetailStroeTransitionItem,
      });
    }
    handleSaveMixCutDetailSave();
  };

  const handleTransitionItemIsGlobaltoTrue = (key: string) => {
    const newTransitions = { ...mixCutDetailStore.getTransition() };
    const effect = newTransitions[key].effects;
    Object.keys(newTransitions).forEach((item) => {
      newTransitions[item].effects = [...effect];
    });
    mixCutDetailStore.setTransition(newTransitions);
  };

  return {
    transition: mixCutDetailStore.getTransition(),
    handleTransitionItemAdd,
    handleTransitionItemRemove,
    handleTransitionItemChange,
    handleTransitionItemEffectsRemove,
    handleTransitionItemIsGlobaltoTrue,
  };
};

export default useTransition;
