import mixCutDetailStore from "@/stores/mixCutDetailStore";
import useMixCutDetail from "./useMixCutDetail";
import { saveEngineeringContent } from "@/services/mixCutDetail/mixCutDetail";
import moment from "moment";
import usePreviewInfo from "./usePreviewInfo";

interface IMixCutDetailSavaReturn {
  handleSaveMixCutDetailSave: () => void;
}

let debounceTimeout: NodeJS.Timeout | null = null;

const useMixCutDetailSave = (): IMixCutDetailSavaReturn => {
  const { handleGetOriginJson } = useMixCutDetail();
  const { calcPreviewInfo } = usePreviewInfo();

  const handleSaveMixCutDetailSave = () => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(() => {
      const mixCutDetailMsg = { ...mixCutDetailStore.getProjectMsg() };
      const bigObj = handleGetOriginJson();
      const content = JSON.stringify(bigObj);
      const result = saveEngineeringContent(mixCutDetailMsg.id as any, content);
      mixCutDetailMsg.updated_at = moment(Date.now()).format(
        "YYYY-MM-DD HH:mm",
      ) as any;
      mixCutDetailStore.setProjectMsg({ ...mixCutDetailMsg });
      calcPreviewInfo(bigObj);
    }, 3000);
  };

  return {
    handleSaveMixCutDetailSave,
  };
};

export default useMixCutDetailSave;
