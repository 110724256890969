import mixCutDetailStore from "@/stores/mixCutDetailStore";
import checkStore from "@/stores/mixCutDetailStore/check/check";
import {
  ICaptionError,
  ISencesError,
} from "@/stores/mixCutDetailStore/check/type";
import { IOrigin } from "@/stores/mixCutDetailStore/type";
// import { estimateDuration, generateCombinations } from "@/utils/perm";

interface IMixCutDetailCheck {
  sencesError: ISencesError;
  captionError: ICaptionError;
  toIndexDeleteSencesError: (index: number) => void;
  combSenceMaterials: (data: IOrigin) => number;
  checkJson: () => void;
  toIndexGetError: (index: number) => string;
  confirmSencesErrorIsEmpty: () => boolean;
  confirmCaptionsErrorIsEmpty: () => boolean;
  handleErrorClear: () => void;
}

const useMixCutDetailCheck = (): IMixCutDetailCheck => {
  const combSenceMaterials = (data: IOrigin): number => {
    return null;
  };
  const handleErrorClear = () => {
    checkStore.setCaptionError([]);
    checkStore.setGlobalError(null);
    checkStore.setSencesError([]);
  };
  // const combSenceMaterials = (data: IOrigin): number => {
  //   const scencesCombCount: number[] = [];
  //   for (let i = 0; i < data.scenes.length; i++) {
  //     const scene = data.scenes[i];
  //     const duration = estimateDuration(scene.caption[i]._preview); // 估计台词时间
  //     const combinationLength =
  //       scene.setting.clip_rule === "single_video" ? 1 : scene.materials.length; // 单镜头时使用单个素材，多镜头时使用素材数组
  //     const usedElements = new Set(); // 使用过的元素缓存
  //     const uniqueElements = scene.setting.clip_rule === "single_times"; // 是否只使用一次（单次混剪)
  //     let combCount = 0;
  //     for (let j = 0; j < combinationLength; j++) {
  //       const combinations = generateCombinations(
  //         scene.materials,
  //         j + 1,
  //         usedElements,
  //         uniqueElements,
  //       );
  //       for (const comb of combinations) {
  //         // 全局配置下不判断分镜
  //         if (
  //           data.global_settings.enabled ||
  //           isValid(comb, duration, scene.setting.speed)
  //         ) {
  //           // console.log(
  //           //   "Item:",
  //           //   comb.map((v) => v.id),
  //           // );
  //           combCount++;
  //         }
  //       }
  //     }
  //     if (combCount === 0) {
  //       // TODO 表示第i个分镜素材无法组合出结果
  //       checkStore.setSencesError([
  //         ...checkStore.getSencesError(),
  //         {
  //           index: i,
  //           message: "分镜下素材与台词无法生成合适结果",
  //         },
  //       ]);
  //       console.log("分镜下素材与台词无法生成合适结果");
  //     }
  //     scencesCombCount.push(combCount);
  //   }
  //   console.log("cesCombCount", scencesCombCount);
  //   return scencesCombCount.reduce((sum, cur) => sum * cur, 1);
  // };

  const toIndexDeleteSencesError = (index: number) => {
    const sencesErrors = [...checkStore.getSencesError()];
    const deleteItemIndex = sencesErrors.findIndex(
      (item) => item.index === index,
    );
    if (deleteItemIndex > -1) {
      sencesErrors.splice(deleteItemIndex, 1);
    }
    checkStore.setSencesError([...sencesErrors]);
  };

  const checkSences = () => {
    const sences = mixCutDetailStore.getProjectSences();
    const emptyMaterialsIndices = sences
      .map((scene, index) => (scene.materials.length === 0 ? index : -1))
      .filter((index) => index !== -1);
    const errorSences = emptyMaterialsIndices.map((item) => {
      return {
        index: item,
        message: "镜头组视频数据为空",
      };
    });
    checkStore.setSencesError(errorSences);
  };

  const checkJson = () => {
    checkSencesAndCaptionInit();
    checkSences();
    // if (confirmSencesErrorIsEmpty.length > 0) return;
  };

  const checkSencesAndCaptionInit = () => {
    checkStore.setCaptionError([]);
    checkStore.setSencesError([]);
  };

  const toIndexGetError = (index: number) => {
    const sencesError = (checkStore.getSencesError() || []).find((step) => {
      return step.index === index;
    })?.message;
    const captionError = (checkStore.getCaptionError() || []).find((step) => {
      return step.index === index;
    })?.message;
    if (sencesError && captionError) {
      return `${sencesError}, ${captionError}`;
    } else if (sencesError) {
      return sencesError;
    } else if (captionError) {
      return captionError;
    }
    return null;
  };

  const confirmSencesErrorIsEmpty = () => {
    const sencesError = checkStore.getSencesError().filter((item) => item);
    return sencesError.length === 0;
  };

  const confirmCaptionsErrorIsEmpty = () => {
    const captionsError = checkStore.getCaptionError().filter((item) => item);
    return captionsError.length === 0;
  };

  return {
    handleErrorClear,
    sencesError: checkStore.getSencesError(),
    captionError: checkStore.getCaptionError(),
    toIndexDeleteSencesError,
    checkJson,
    toIndexGetError,
    confirmSencesErrorIsEmpty,
    combSenceMaterials,
    confirmCaptionsErrorIsEmpty,
  };
};

export default useMixCutDetailCheck;
