import http from "@/utils/http";
import axios from "axios";
import { IAliOssToken } from "./fileUpload/fileUpload";
/**
 * 查找当前团队的所有权益
 * **/
export async function teme_package(params): Promise<any> {
  try {
    const res = await http.get("/api/v1/team/package", { params });
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

/**
 *
 * 移交管理员
 * */
export async function team_transfer(params): Promise<any> {
  try {
    const res = await http.post("/api/v1/team/transfer", params);
    console.log(params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

/**
 *
 * 查询权益流水和交易记录
 * */
export async function team_package_record(params?): Promise<any> {
  try {
    const res = await http.get("/api/v1/team/package/record", { params });
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

/**
 *
 * 企业认证
 * */
export async function team_corporate(params?): Promise<any> {
  try {
    const res = await http.post("/api/v1/team/company/info", { params });
    console.log(params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
/**
 *
 * 获取企业认证
 * */
export async function team_corporate_get(params?): Promise<any> {
  try {
    const res = await http.get("/api/v1/team/company/info");
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

/**
 *
 * 查询与销售想拼配的 二维码
 * */
export async function user_wechat_qrcode(): Promise<any> {
  try {
    const res = await http.get("/api/v1/user/wechat_qrcode");
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

/**
 *
 * 上传文件 企业 认证
 */
export const getDocumentUploadToken = async (name: string, size: number) => {
  try {
    const result = await http.post(`/api/v1/space/oss/upload`, {
      name: name,
      method: "file",
      size,
    });
    return result?.data;
  } catch (error) {
    console.error("Error fetching upload token:", error);
    throw error;
  }
};
/**
 *
 * 上传文件 企业 认证 oss
 */
export const setFile_teamOverview = async (
  token: IAliOssToken,
  file: File,
): Promise<void> => {
  const newFormData = new FormData();

  newFormData.append("key", `${token.dir + file.name}`);
  newFormData.append("callback", token.callback);
  newFormData.append("OSSAccessKeyId", token.accessid);
  newFormData.append("policy", token.policy);
  newFormData.append("Signature", token.signature);
  newFormData.append("expire", token.expire);
  newFormData.append("success_action_status", "200");
  newFormData.append("Content-Disposition", `attachment`);
  newFormData.append("file", file);

  const response = await http.post(token.host, newFormData, {
    timeout: 100000000,
    headers: {
      "Content-Type": "multipart/form-data",
      a: "ccc",
    },
  });
  return response.data;
  // if (response.status === 200) {
  //     // 成功后的逻辑

  // }
};

/**
 *
 *  上传企业认证 进行 ocr认证
 * */
export async function team_license_ocr(data): Promise<any> {
  try {
    const res = await http.post("/api/v1/team/company/license/ocr", data);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

/**
 *
 *  获取可切换的所有团队
 * */
export async function team_addop(data): Promise<any> {
  try {
    const res = await http.post("/api/v1/team/", data);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
