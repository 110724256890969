import http from "@/utils/http";

export interface IGetProjectList {
  keyword?: string;
  creator?: string;
}

export const generateGraphQLQuery =
  (data: IGetProjectList) =>
  (fields: string[]): string => {
    const selectedFields = fields.join("\n");
    const searchParams: string[] = [];
    if (data.keyword !== undefined)
      searchParams.push(`keyword: "${data.keyword}"`);
    if (data.creator !== undefined)
      searchParams.push(`creator: ${data.creator}`);
    const searchStr = searchParams.join(", ");
    return `{
        project_list {
         ${selectedFields} 
      }
    }`;
  };

export const queryFields: string[] = [
  "id",
  "team_id",
  "name",
  "owner_uuid",
  "owner_name",
  "type",
  "created_at",
  "updated_at",
  "members",
];

export interface IProjectListResponse {
  created_at: number;
  id: number;
  name: string;
  owner_name: string;
  owner_uuid: string;
  team_id: number;
  type: number;
  updated_at: number;
  members: string[];
}

export const getPorjectList = async (
  data: IGetProjectList,
): Promise<IProjectListResponse[]> => {
  const result = await http.post(
    `/api/v1/graphql/`,
    generateGraphQLQuery(data)(queryFields).toString(),
    {
      headers: {
        "Content-Type": "application/graphql",
      },
    },
  );
  return result.data.project_list;
};
