import { makeAutoObservable } from "mobx";
import allAllowType, {
  musicAllowType,
  pictureAllowType,
  videAllowType,
} from "@/config/allowType";

class AllowTypeStore {
  private allowType: string[] = allAllowType;
  private videoType: string[] = videAllowType;
  private pictureType: string[] = pictureAllowType;
  private musicType: string[] = musicAllowType;
  constructor() {
    makeAutoObservable(this);
  }

  getAllowType() {
    return this.allowType;
  }

  getAllowTypeString() {
    const uniqueExtensions = Array.from(
      new Set(this.allowType.join(",").split(",")),
    );
    return uniqueExtensions.join(",");
  }

  getAllVideoTypeString() {
    const uniqueExtensions = Array.from(
      new Set(this.videoType.join(",").split(",")),
    );
    return uniqueExtensions.join(",");
  }

  getAllPictureTypeString() {
    const uniqueExtensions = Array.from(
      new Set(this.pictureType.join(",").split(",")),
    );
    return uniqueExtensions.join(",");
  }

  getAllMusicTypeString() {
    const uniqueExtensions = Array.from(
      new Set(this.musicType.join(",").split(",")),
    );
    return uniqueExtensions.join(",");
  }
}

const allowTypeStore = new AllowTypeStore();
export default allowTypeStore;
