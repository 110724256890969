// stores/counterStore.js
import { makeAutoObservable } from "mobx";

class CounterStores {
  count = 0;
  constructor() {
    makeAutoObservable(this);
  }

  increment() {
    this.count++;
  }

  decrement() {
    this.count--;
  }
}

const counterStore = new CounterStores();
export default counterStore;
