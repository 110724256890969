import http from "@/utils/http";
export type IdsType = number | number[] | string | string[];
export interface IScriptItem {
  content: string;
  id: number;
  industry: string;
  product: string;
  used_count: number;
  title: string;
  update_at: number;
}

export interface IScriptRequest {
  keyword: string;
  sort_field?: string;
  sort_sc: string;
  project_id: number;
  page: number;
  page_size: number;
}
export const getScriptList = async (
  e: IScriptRequest,
): Promise<{ data: IScriptItem[]; total: number }> => {
  const result = await http.get(`/api/v1/script/find`, {
    params: e,
  });
  return { data: result.data.items, total: result.data.sum };
};

export const toNumberList = (ids: IdsType) => {
  if (Array.isArray(ids)) {
    return ids.map((item) => Number(item));
  }
  return [Number(ids)];
};

export const scriptBatchDelete = async (ids: IdsType) => {
  const newIds = toNumberList(ids);
  const result = await http.delete(`/api/v1/script/batch/delete`, {
    data: {
      ids: toNumberList(newIds),
    },
  });
  return result;
};

export const scriptCopy = async (data: {
  id: number;
  project_id: number;
}): Promise<IScriptItem> => {
  const result = await http.post(`/api/v1/script/copy`, {
    ...data,
  });
  return result.data.item;
};

export const toIdGetScript = async (id: string | number) => {
  const result = await http.get(`/api/v1/script/${id}`);
  return result.data.item;
};

export interface IHotScriptResponse {
  aspect: string;
  attribute: string;
  content: string;
  cover_url: string;
  created_at: number;
  example_video: string;
  example_video_duration: number;
  height: number;
  id: number;
  industry_id: number;
  material_count: number;
  musics: string;
  scene_count: number;
  speakers: string;
  strategy: string;
  style_id: number;
  subtitles: string;
  title: string;
  updated_at: number;
  width: number;
}

export const toIdGetHotScript = async (
  id: string | number,
): Promise<IHotScriptResponse> => {
  const result = await http.get(`/api/v1/script/hot/${id}`);
  return result.data.item;
};

// 无延迟获取协同脚本
export const toIdGetCustomScript = async (id: string | number) => {
  const result = await http.get(`/api/v1/share_doc/get`, {
    params: { doc_id: id, doc_source: "script" },
  });
  return result.data.doc;
};

export const addCustomScriptRefenceCount = async (id: number) => {
  await http.put(`/api/v1/script/${id}/count`, {
    type: 1,
  });
};

export const addHotScriptRefenceCount = async (id: number) => {
  await http.put(`/api/v1/script/${id}/count`, {
    type: 2,
  });
};
