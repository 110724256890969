import { makeAutoObservable } from "mobx";
import {} from "./type";

export interface IError {
  scene: number;
  message: string;
}

export interface ITimer {
  maxDuration: number;
  minDuration: number;
}

class preivewInfo {
  private maxCount: number = null;
  private timer: ITimer = {
    maxDuration: 0,
    minDuration: 0,
  };
  private error: IError[] = null;
  constructor() {
    makeAutoObservable(this);
  }

  public getTimer() {
    return this.timer;
  }

  public getError() {
    return this.error;
  }

  public getMaxCount() {
    return this.maxCount;
  }

  public setTimer(timer: ITimer) {
    this.timer = timer;
  }

  public setMaxCount(maxCount: number) {
    this.maxCount = maxCount;
  }

  public setError(errors: IError[]) {
    this.error = errors;
  }
}

const preivewInfoStore = new preivewInfo();
export default preivewInfoStore;
