// NavStore.ts
import { makeAutoObservable, observable } from "mobx";

export enum ProjectNavTypeEnum {
  "DEFAULT",
  "ALL",
  "PRIVTE",
}

export interface IProjectItem {
  id?: number;
  name: string;
  type?: ProjectNavTypeEnum;
  memberIds: string[];
  owner_name?: string;
}

class NavStore {
  private projectList: IProjectItem[] = observable([]);
  private projectId: number = null;

  constructor() {
    makeAutoObservable(this);
  }

  getProjectList() {
    return this.projectList;
  }

  setProjectList(navList: IProjectItem[]) {
    this.projectList = navList;
  }

  getProjectId() {
    return this.projectId;
  }

  setProjectId(id: number) {
    this.projectId = id;
  }
}

const navStore = new NavStore();

export default navStore;
