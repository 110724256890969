/* eslint-disable no-undef */
import axios from "axios";
import { Message } from "@arco-design/web-react";
import { getTokenToLocalStorage } from "@/utils/token";
import { whiteListRequest } from "@/config/whiteLIst";
// 需要跳过 Token 校验的路由

const http = axios.create({
  // baseURL:
  //   process.env.REACT_APP_MODE === "development"
  //     ? ""
  //     : process.env.REACT_APP_PROXY,
  timeout: 100000,
});

// 请求拦截器
http.interceptors.request.use(
  (config) => {
    const requestUrl = config.url;
    const isWhitelisted = whiteListRequest.some((route) => {
      return route === requestUrl;
    });
    // 跳过 Token 验证和头部附加
    if (isWhitelisted) {
      return config;
    }
    const token = getTokenToLocalStorage();
    if (!token) {
      window.location.href = "/login";
      return Promise.reject("没有找到 Token，请重新登录");
    }
    try {
      const tokenParts = token.split(".");
      if (tokenParts.length !== 3) {
        throw new Error("Token 格式不正确");
      }
      const payload = tokenParts[1];
      const decodedPayload = JSON.parse(
        // 特殊字符处理 解决非 ASCII 字符或转义字符 parse 报错
        decodeURIComponent(
          atob(payload)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(""),
        ),
      );

      // exp 时间戳
      const exp = decodedPayload.exp;
      const currentTime = Math.floor(Date.now() / 1000);
      // Token 是否过期
      if (exp < currentTime) {
        window.location.href = "/login";
        Message.error("Token 已过期，请重新登录");
        return Promise.reject("Token 已过期，请重新登录");
      } else {
        config.headers["Token"] = `${token}`;
        return config;
      }
    } catch (error) {
      window.location.href = "/login";
      Message.error("Token 无效，请重新登录");
      return Promise.reject("Token 无效，请重新登录");
    }
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 响应拦截器
http.interceptors.response.use(
  (response) => {
    if (response.config.url.includes("graphql")) {
      if (response.data.data) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject(response.data.message);
      }
    }
    if (response.status === 200 || response.status === 207) {
      if (response.data.code === 0) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject(response.data.message);
        // Message.error(response.data.message);
        // return Promise.reject(response.data);
      }
    }

    return Promise.reject("请求失败");
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.localStorage.clear();
      window.location.href = "/login";
      return;
    }

    return Promise.reject(error);
  },
);

export default http;
