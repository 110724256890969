import { script_parse, script_parse_progress, script_parse_rdid, script_parse_rdidget, script_parse_record, script_parse_retry } from "@/services/videoallyis";
import { Message } from "@arco-design/web-react";
import { makeAutoObservable } from "mobx";
import Roostore from "./index"

class Videoany {
    count = 0;
    tem_id = 2
    constructor() {
        makeAutoObservable(this);
    }


    voideodata = [
        {
            title: "无边的大草原",
            pdata: ["美食", "火锅", "美味"],
            titlesc: "素材",
            dataTime: "uploadanalysis",
            copunt: 0,
        },
        {
            title: "无边的大草原",
            pdata: ["美食", "火锅", "美味"],
            titlesc: "素材",
            dataTime: "uploadanalysis",
            copunt: 0,
        },
        {
            title: "无边的大草原",
            pdata: ["美食", "火锅", "美味"],
            titlesc: "素材",
            dataTime: "uploadanalysis",
            copunt: 0,
        },
        {
            title: "无边的大草原",
            pdata: ["美食", "火锅", "美味"],
            titlesc: "素材",
            dataTime: "uploadanalysis",
            copunt: 0,
        },
        {
            title: "无边的大草原",
            pdata: ["美食", "火锅", "美味"],
            titlesc: "素材",
            dataTime: "uploadanalysis",
            copunt: 0,
        },
        {
            title: "无边的大草原",
            pdata: ["美食", "火锅", "美味"],
            titlesc: "素材",
            dataTime: "uploadanalysis",
            copunt: 0,
        },
        {
            title: "无边的大草原",
            pdata: ["美食", "火锅", "美味"],
            titlesc: "素材",
            dataTime: "uploadanalysis",
            copunt: 0,
        },
        {
            title: "无边的大草原",
            pdata: ["美食", "火锅", "美味"],
            titlesc: "素材",
            dataTime: "uploadanalysis",
            copunt: 0,
        },
        {
            title: "无边的大草原",
            pdata: ["美食", "火锅", "美味"],
            titlesc: "素材",
            dataTime: "uploadanalysis",
            copunt: 0,
        },
        {
            title: "无边的大草原",
            pdata: ["美食", "火锅", "美味"],
            titlesc: "素材",
            dataTime: "uploadanalysis",
            copunt: 0,
        },

    ]
    data = [

    ]
    datatotal = null
    detilsbole = null;  // 详情页弹窗状态
    detilesid = null;
    filters = {       // 分页 
        page_limit: 23,
        page_index: 1,
    }

    savadatfiltsize(index) {
        this.filters.page_index = index;
        this.savaviodedata();

    }

    savaviodedata() {
        this.script_parse_record_to(this.filters);
        // const data = this.voideodata.slice((this.filters.page_index - 1) * this.filters.page_limit, this.filters.page_index * this.filters.page_limit);
        // // data
        // alert(this.filters.page_index)
        // this.data = data
    }
    /***
     * 解析视频
     * ****/
    script_parse_to(data) {
        try {
            script_parse(data).then((res) => {
                if (res?.code == 0 && res?.message == "OK") {
                    const { page_limit, page_index } = this.filters
                    this.script_parse_record_to({
                        page_limit: String(page_limit),
                        page_index: String(page_index),
                    })
                } else {
                    Message.info(res);
                }
            });


        } catch (error) {
            console.error("Login failed:", error);
        } finally {
        }
    }
    /***
   *  视频解析记录
   * ****/
    async script_parse_record_to(data) {
        try {
            await script_parse_record(data).then((res) => {
                if (res?.code == 0 && res?.message == "OK") {
                    if (JSON.stringify(res.data) == "{}") {
                        return
                    }
                    this.data = res.data.infos
                    this.datatotal = res.data.total
                    // Message.success("成功");
                } else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error("Login failed:", error);
        } finally {
        }
    }
    /***
    *  视频解析 进度 
    * ****/
    script_parse_progress_to(data) {
        try {
            return script_parse_progress(data).then((res) => {
                if (res?.code == 0 && res?.message == "OK") {
                    if (JSON.stringify(res.data) == "{}") {

                    }
                    return res.data
                } else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error("Login failed:", error);
        } finally {
        }
    }
    /***
   *  删除视频解析
   * ****/
    script_parse_delete_to(data) {
        try {
            return script_parse_rdid(data).then((res) => {
                if (res?.code == 0 && res?.message == "OK") {
                    if (JSON.stringify(res.data) == "{}") {
                        return
                    }
                    this.script_parse_record_to(this.filters)
                    // Message.success("删除成功");
                } else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error("Login failed:", error);
        } finally {
        }
    }

    /***
  *  重新解析视频
  * ****/
    script_parse_retry_to(data) {
        try {
            return script_parse_retry(data).then((res) => {
                if (res?.code == 0 && res?.message == "OK") {
                    if (JSON.stringify(res.data) == "{}") {
                        return
                    }
                    this.script_parse_record_to(this.filters)
                    // Message.success("删除成功");
                } else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error("Login failed:", error);
        } finally {
        }
    }
    /***
 *   视频 解析 详情 
 * ****/
    script_parse_rdidget_detilsto(data) {
        try {
            return script_parse_rdidget(data).then((res) => {
                if (res?.code == 0 && res?.message == "OK") {
                    if (JSON.stringify(res.data) == "{}") {
                        return
                    }

                    Message.success("获取详情成功");
                    return res.data
                } else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error("Login failed:", error);
        } finally {
        }
    }

}

const videoallyis = new Videoany();
export default videoallyis;

