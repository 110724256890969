import mixCutDetailStore from "@/stores/mixCutDetailStore";
import {
  ITitle,
  ITitleContentItem,
} from "@/stores/mixCutDetailStore/type/title";
import useMixCutDetailInit from "./useMixCutDetailInit";
import useMixCutDetail from "./useMixCutDetail";
import useMixCutDetailSave from "./useMixCutDetailSave";
import { Message } from "@arco-design/web-react";
import { toIndexGetInitTitleTextSetting } from "@/stores/mixCutDetailStore/init";
import _ from "lodash";
import usePreviewConfig from "../preview/usePreview";

interface IUseTitleReturn {
  titleList: ITitle;
  handleTitleItemAdd: () => void;
  handleTitleItemRemove: (index: number) => void;
  handleTitleItemChange: (
    index: number,
    currentIndex: number,
    data: ITitleContentItem,
  ) => void;
  handleTitleClear: () => void;
  handelTitleItemAddItem: (index: number) => void;
  handelSwapTitleItem: (destination: number, source: number) => void;
  handleTitleItemSimpleRemove: (group: number, index: number) => void;
}

const useTitle = (): IUseTitleReturn => {
  const { initTitleGroup } = useMixCutDetailInit();
  const { handleSaveMixCutDetailSave } = useMixCutDetailSave();
  const { handlePreviewTitleChange } = usePreviewConfig();
  const { global } = useMixCutDetail();
  const handleTitleItemAdd = () => {
    const titleList = [...mixCutDetailStore.getProjectTitle()];
    mixCutDetailStore.setProTitle([
      ...titleList,
      {
        id: Math.floor(Date.now() + Math.random() * 100000),
        content: [
          {
            id: Math.floor(Date.now() + Math.random() * 100000),
            text_setting: { ...toIndexGetInitTitleTextSetting(0) },
            text: "",
            _text: [
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: "",
                  },
                ],
              },
            ],
          },
        ],
      },
    ]);
    handleSaveMixCutDetailSave();
  };

  const handleTitleItemRemove = (index: number) => {
    if (mixCutDetailStore.getProjectTitle().length === 1) {
      Message.error("必须保留一个台词组，无法删除！");
      return;
    }
    const titleList = [...mixCutDetailStore.getProjectTitle()];
    titleList.splice(index, 1);
    mixCutDetailStore.setProTitle(titleList);
    handleSaveMixCutDetailSave();
  };

  const handleTitleItemSimpleRemove = (groupIndex: number, index: number) => {
    const newTitleList = mixCutDetailStore.getProjectTitle();
    newTitleList[groupIndex].content.splice(index, 1);
    mixCutDetailStore.setProTitle([...newTitleList]);
    handleSaveMixCutDetailSave();
    handlePreviewTitleChange(_.cloneDeep(newTitleList[groupIndex]));
  };

  const handleTitleItemChange = (
    index: number,
    currentIndex: number,
    data: ITitleContentItem,
  ) => {
    const titleList = [...mixCutDetailStore.getProjectTitle()];
    titleList[index].content[currentIndex] = data;
    mixCutDetailStore.setProTitle(titleList);
    handleSaveMixCutDetailSave();
    handlePreviewTitleChange(_.cloneDeep(titleList[index]));
  };

  const handelTitleItemAddItem = (index: number) => {
    const titleList = [...mixCutDetailStore.getProjectTitle()];
    titleList[index].content.push({
      id: Math.floor(Date.now() + Math.random() * 100000),
      text: "",
      _text: [
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "",
            },
          ],
        },
      ],
      text_setting: {
        ...toIndexGetInitTitleTextSetting(titleList[index].content.length),
      },
    });
    mixCutDetailStore.setProTitle(titleList);
    handleSaveMixCutDetailSave();
    handlePreviewTitleChange(_.cloneDeep(titleList[index]));
  };

  const handelSwapTitleItem = (destination: number, source: number) => {
    const titleList = [...mixCutDetailStore.getProjectTitle()];
    [titleList[destination], titleList[source]] = [
      titleList[source],
      titleList[destination],
    ];

    mixCutDetailStore.setProTitle(titleList);
    handleSaveMixCutDetailSave();
  };

  const handleTitleClear = () => {
    if (global) {
      initTitleGroup();
      handleSaveMixCutDetailSave();
    }
  };

  return {
    titleList: mixCutDetailStore.getProjectTitle(),
    handleTitleItemAdd,
    handleTitleItemRemove,
    handleTitleItemChange,
    handelTitleItemAddItem,
    handleTitleClear,
    handelSwapTitleItem,
    handleTitleItemSimpleRemove,
  };
};

export default useTitle;
