import mixCutDetailStore from "@/stores/mixCutDetailStore";
import { ICaption } from "@/stores/mixCutDetailStore/type/caption";
import useMixCutDetailSave from "./useMixCutDetailSave";
import { initCaptionItem } from "@/stores/mixCutDetailStore/init";
import useTitle from "./useTitle";
import usePreviewConfig from "../preview/usePreview";
interface IUseCaptionReturn {
  projectCaption: ICaption[][];
  handleProjectCaptionToIndexChange: (
    groundIndex: number,
    index: number,
    caption: ICaption,
  ) => void;
  handleProjectCaptionItemAddItem: (groupIndex: number) => void;
  handleProjectCaptionChange: (captions: ICaption[][]) => void;
  handleSwapCaptionItem: (destination: number, source: number) => void;
  handleCaptionItemDelete: (index: number) => void;
  handleCaptionItemAdd: () => void;
  hanldeProjectCaptionItemDeleteItem: (group: number, index: number) => void;
  handleCaptionVoiceIsGlobalToChangeTrue: (
    groudIndex: number,
    index: number,
  ) => void;
  handleCaptionTextIsGlobalToChangeTrue: (
    groundInde: number,
    index: number,
  ) => void;
}

const useCaption = (): IUseCaptionReturn => {
  const { handleSaveMixCutDetailSave } = useMixCutDetailSave();
  const { handlePreviewCaptionChange } = usePreviewConfig();
  const { titleList } = useTitle();
  const handleProjectCaptionChange = (captions: ICaption[][]) => {
    mixCutDetailStore.setProjectCaption(captions);
    handleSaveMixCutDetailSave();
  };

  const handleProjectCaptionToIndexChange = (
    groudIndex: number,
    index: number,
    caption: ICaption,
  ) => {
    console.log(caption);
    const captions = [...mixCutDetailStore.getProjectCaption()];
    captions[groudIndex][index] = caption;
    mixCutDetailStore.setProjectCaption(captions);
    if (caption.voice_setting.is_global === true) {
      handleCaptionVoiceIsGlobalToChangeTrue(groudIndex, index);
    }
    if (caption.text_setting.is_global === true) {
      handleCaptionTextIsGlobalToChangeTrue(groudIndex, index);
    }
    handlePreviewCaptionChange(caption);
    handleSaveMixCutDetailSave();
  };

  // 添加台词组，台词小组
  const handleProjectCaptionItemAddItem = (group: number) => {
    const captions = [...mixCutDetailStore.getProjectCaption()];
    captions[group] = [
      ...captions[group],
      {
        ...initCaptionItem,
        id: Math.floor(Date.now() + Math.random() * 10000),
      },
    ];
    mixCutDetailStore.setProjectCaption([...captions]);
    handleSaveMixCutDetailSave();
  };

  const hanldeProjectCaptionItemDeleteItem = (group: number, index: number) => {
    const captions = [...mixCutDetailStore.getProjectCaption()];
    captions[group].splice(index, 1);
    mixCutDetailStore.setProjectCaption([...captions]);
    handleSaveMixCutDetailSave();
  };

  const handleSwapCaptionItem = (destination: number, source: number) => {
    const captions = mixCutDetailStore.getProjectCaption();
    [captions[destination], captions[source]] = [
      captions[source],
      captions[destination],
    ];
    mixCutDetailStore.setProjectCaption([...captions]);
    handleSaveMixCutDetailSave();
  };

  const handleCaptionItemDelete = (index: number) => {
    const newCaptionList = [...mixCutDetailStore.getProjectCaption()];
    newCaptionList.splice(index, 1);
    mixCutDetailStore.setProjectCaption(newCaptionList);
  };

  const handleCaptionItemAdd = () => {
    const newCaptionList = [...mixCutDetailStore.getProjectCaption()];
    newCaptionList.push([
      {
        id: Math.floor(Date.now() + Math.random() * 100000),
        ...initCaptionItem,
      },
    ]);
    mixCutDetailStore.setProjectCaption([...newCaptionList]);
    handleSaveMixCutDetailSave();
  };

  const handleCaptionVoiceIsGlobalToChangeTrue = (
    groudIndex: number,
    index: number,
  ) => {
    const newCaptionList = [...mixCutDetailStore.getProjectCaption()];
    const voiceSetting = newCaptionList[groudIndex][index].voice_setting;
    newCaptionList.forEach((item) => {
      item.forEach((step) => {
        step.voice_setting = {
          ...voiceSetting,
          is_global: step.voice_setting.is_global,
        };
      });
    });
    mixCutDetailStore.setProjectCaption(newCaptionList);
    handleSaveMixCutDetailSave();
  };

  const handleCaptionTextIsGlobalToChangeTrue = (
    groudIndex: number,
    index: number,
  ) => {
    const newCaptionList = [...mixCutDetailStore.getProjectCaption()];
    const voiceSetting = newCaptionList[groudIndex][index].text_setting;
    newCaptionList.forEach((item) => {
      item.forEach((step) => {
        step.text_setting = {
          ...voiceSetting,
          is_global: step.text_setting.is_global,
        };
      });
    });
    mixCutDetailStore.setProjectCaption(newCaptionList);
    handleSaveMixCutDetailSave();
  };

  return {
    projectCaption: mixCutDetailStore.getProjectCaption(),
    handleProjectCaptionItemAddItem,
    hanldeProjectCaptionItemDeleteItem,
    handleProjectCaptionChange,
    handleProjectCaptionToIndexChange,
    handleSwapCaptionItem,
    handleCaptionItemDelete,
    handleCaptionItemAdd,
    handleCaptionVoiceIsGlobalToChangeTrue,
    handleCaptionTextIsGlobalToChangeTrue,
  };
};

export default useCaption;
