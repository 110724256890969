import { makeAutoObservable } from "mobx";
import { Message } from "@arco-design/web-react";
import {
  teme_package,
  team_transfer,
  team_package_record,
  user_wechat_qrcode,
  team_corporate,
  team_corporate_get,
  getDocumentUploadToken,
  setFile_teamOverview,
  team_license_ocr,
} from "@/services/temeover";
class teamover_to {
  dataactive = "activeqy";
  // 团队权益 列表
  qaydata = [
    {
      total: 0,
      supertotal: 0,
      name: "视频合成条数",
    },
    {
      total: 0,
      supertotal: 0,
      name: "存储空间",
    },
    {
      total: 0,
      supertotal: 0,
      name: "团队成员账号",
    },
  ];

  supertoaldata = {
    total: null,
    supertotal: null,
  };

  ewcode = "";
  // 企业认证 状态
  qyrzstatus = null;
  qyrzdata = {};

  constructor() {
    makeAutoObservable(this);
  }
  setactive(a) {
    this.dataactive = a;
  }
  setoplist(a) {
    console.log(a);
    const { infos = [], items = [] } = a;
    const list = [];
    items.forEach((item) => {
      infos.forEach((itemop) => {
        if (itemop.type == item.benefit_type) {
          if (itemop.name == "视频合成条数" && itemop.type == "1") {
            this.qaydata[0] = {
              name: "视频合成条数",
              total: item.total,
              supertotal: item.surplus,
            };
          }
          if (itemop.name == "存储空间" && itemop.type == "3") {
            this.qaydata[1] = {
              name: "存储空间",
              total: item.total,
              supertotal: item.surplus,
            };
          }
          if (itemop.name == "团队成员账号" && itemop.type == "2") {
            this.qaydata[2] = {
              name: "团队成员账号",
              total: item.total,
              supertotal: item?.surplus || 0,
            };
          }
          if (item.benefit_type == "2") {
            this.supertoaldata = {
              total: item.total,
              supertotal: item?.surplus || 0,
            };
          }
        }
      });
    });
    console.log(this.qaydata);
  }

  /**
   * *
   *  查询当前权益
   * *
   */

  async teme_package_to(data?) {
    try {
      await teme_package({
        state: 3,
      }).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          this.setoplist(res.data);
          // Message.success("成功");
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error("failed:", error);
    } finally {
    }
  }

  /**
   * *
   *  交易 流水记录
   * *
   */
  async team_package_record_to() {
    try {
      await team_package_record().then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          // Message.success("成功");
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error("failed:", error);
    } finally {
    }
  }
  /**
   * *
   *  查询当前团队匹配的二维码
   * *
   */
  async user_wechat_qrcode_to() {
    try {
      await user_wechat_qrcode().then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          this.ewcode = res.data.sales_wechat_qrcode;
          // Message.success("成功");
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error("failed:", error);
    } finally {
    }
  }
  /**
   * *
   *  移交管理员
   * *
   */
  async team_transfer_to(params) {
    try {
      await team_transfer({
        new_owner_uuid: params,
      }).then((res) => {
        console.log(res);
        if (res?.message == "OK") {
          Message.success("管理员移交成功");
          window.location.href = "/work-bench";
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error("failed:", error);
    } finally {
    }
  }

  /**
   * *
   *  企业认证
   * *
   */
  async team_corporate_to(params) {
    try {
      await team_corporate(params).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          // console.log(res.data);
          // this.ewcode = res.data.sales_wechat_qrcode
          if (res?.data?.item?.status) {
            this.qyrzstatus = Number(res?.data?.item?.status);
            this.qyrzdata = {
              company_name: res?.data?.item?.company_name,
              credit_code: res?.data?.item?.credit_code,
              legal_rep: res?.data?.item?.legal_rep,
            };
          }
          Message.success("认证提交成功");
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error("failed:", error);
    } finally {
    }
  }

  /**
   * *
   *  获取企业认证状态
   * *
   */
  async team_corporate_get_to() {
    try {
      await team_corporate_get().then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          if (res?.data?.item) {
            if (res?.data?.item?.status) {
              this.qyrzstatus = Number(res?.data?.item?.status);
            } else {
              this.qyrzstatus = 0;
            }
          }
          // Message.success("认证提交成功");
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error("failed:", error);
    } finally {
    }
  }

  /**
   * *
   *  上传 企业认证 get oss 令牌
   * *
   */
  async getDocumentUploadToken_to(name, size) {
    try {
      return await getDocumentUploadToken(name, size).then((res) => {
        if (res) {
          return res;
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error("failed:", error);
    } finally {
    }
  }
  /**
   * *
   *  上传 企业认证 set
   * *
   */

  async setFile_teamOverview_to(token, file) {
    try {
      return await setFile_teamOverview(token, file).then((res) => {
        return res;
      });
    } catch (error) {
      console.error("failed:", error);
    } finally {
    }
  }

  async team_license_ocr_to(file_token) {
    try {
      return await team_license_ocr({
        license_key: file_token,
      }).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          return res.data;
        } else {
          Message.info("请检查上传内容是否符合认证条件");
        }
      });
    } catch (error) {
      console.error("failed:", error);
    } finally {
    }
    // /***
    //  *  邀请团队 ———点击加入当前团队
    //  */
    // async invite_meber_to(datalist) {
    //     try {
    //         return await team_invite_meber(datalist).then((res) => {
    //             if (res.code == 0 && res.message == "OK") {
    //                 Message.success("成功");
    //                 return "111"
    //             } else {
    //                 Message.info(res.message);
    //             }
    //         });
    //     } catch (error) {
    //         console.error('failed:', error);
    //     }
    //     finally {

    //     }
    // }
  }
}

const teamover = new teamover_to();
export default teamover;
