import { IMaterialDetail } from "@/services/createSpace/graphQ/getSimpleMedia";
import {
  IBgResponse,
  ICoverItemResponse,
  IMusicResponse,
} from "@/services/mixCutDetail/mixCutDetail";
import mixCutDetailStore from "@/stores/mixCutDetailStore";
import useMixCutDetailSave from "./useMixCutDetailSave";
import { toJS } from "mobx";

interface IUseCoverAndBgReturn {
  cover: ICoverItemResponse[];
  bgColor: string;
  bgAudio: IMusicResponse[];
  bgImages: (IBgResponse | IMaterialDetail)[];
  bgAudioVolumn: number;
  handleCoverChange: (cover: ICoverItemResponse[]) => void;
  handleBgChange: (bgColor: string) => void;
  handleBgAudiosChange: (bgAudios: IMusicResponse[]) => void;
  handleBgImagesChange: (images: (IBgResponse | IMaterialDetail)[]) => void;
  handleBgAudioVolumn: (volumn: number) => void;
}

const useCoverAndBg = (): IUseCoverAndBgReturn => {
  const { handleSaveMixCutDetailSave } = useMixCutDetailSave();
  const handleBgAudiosChange = (bgAudios: IMusicResponse[]) => {
    console.log(JSON.stringify(bgAudios));
    mixCutDetailStore.setProjectBgAudio(bgAudios);
    handleSaveMixCutDetailSave();
  };

  const handleBgChange = (bgColor: string) => {
    mixCutDetailStore.setProjectBgColor(bgColor);
    handleSaveMixCutDetailSave();
  };
  const handleBgImagesChange = (images: IBgResponse[]) => {
    mixCutDetailStore.setProjectBgImages(images);
    handleSaveMixCutDetailSave();
  };

  const handleCoverChange = (newCover: ICoverItemResponse[]) => {
    mixCutDetailStore.setProjectCover(newCover);
    handleSaveMixCutDetailSave();
  };

  const handleBgAudioVolumn = (volumn: number) => {
    mixCutDetailStore.setProjectBgAudioVolum(volumn);
    handleSaveMixCutDetailSave();
  };

  return {
    bgAudioVolumn: mixCutDetailStore.getProjectBgAudioVolum(),
    cover: mixCutDetailStore.getProjectCover(),
    bgColor: mixCutDetailStore.getProjectBgColor(),
    bgAudio: mixCutDetailStore.getProjectBgAudio(),
    bgImages: mixCutDetailStore.getProjectBgImages(),
    handleBgAudioVolumn,
    handleCoverChange,
    handleBgChange,
    handleBgAudiosChange,
    handleBgImagesChange,
  };
};

export default useCoverAndBg;
