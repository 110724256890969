import {
  CreateEngineeringResponse,
  editEngineering,
} from "@/services/createSpace/engineering";
import mixCutDetailStore from "@/stores/mixCutDetailStore";
import { Message } from "@arco-design/web-react";
import moment from "moment";

interface IUseMixCutDetailMsgReturn {
  mixCutDetailMsg: CreateEngineeringResponse;
  handleChangeMixCutDetailMsg: (data: CreateEngineeringResponse) => void;
  handleChangeMixCutDetailMsgEngineer: (
    data: CreateEngineeringResponse,
  ) => void;
}

const useMixCutDetailMsg = (): IUseMixCutDetailMsgReturn => {
  const handleChangeMixCutDetailMsg = async (
    data: CreateEngineeringResponse,
  ) => {
    try {
      await editEngineering({ ...data });
      mixCutDetailStore.setProjectMsg({
        ...data,
        updated_at: moment().format("YYYY-MM-DD HH:mm") as any,
      });
    } catch (e) {
      Message.error("保存信息失败，请刷新后重试！");
      console.log(e);
    }
  };
  return {
    mixCutDetailMsg: mixCutDetailStore.getProjectMsg(),
    handleChangeMixCutDetailMsg,
    handleChangeMixCutDetailMsgEngineer: mixCutDetailStore.setProjectMsg,
  };
};

export default useMixCutDetailMsg;
