import http from "@/utils/http";

export async function getEditingSticker(params): Promise<any> {
  try {
    const res = await http.get("/api/v1/editing/sticker", { params });
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}
