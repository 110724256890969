import http from "@/utils/http"
// import axios from "axios";
/**
*
* ———点击加入当前团队 
*
* */
export async function team_invite_meber(data): Promise<any> {
    try {
        const res = await http.post(`/api/v1/team/member`, data)
        if (res) {
            return res
        } else {
            console.log('接口错误')
        }
    } catch (err) {
        return err
    }
}
/**
*
* 获取当前链接状态
*
* */
// export async function team_invite_invite(params): Promise<any> {
//     try {
//         const res = await http.get(`/api/v1/team/invite`, { params })
//         if (res) {
//             return res
//         } else {
//             console.log('接口错误')
//         }
//     } catch (err) {
//         return err
//     }
// }

export async function team_invite_invite(data): Promise<any> {
    const query = `
        { invite_state(code:"${data.code}") { nickname team_name state } }`;
    try {
        const res = await http.post(
            `/api/v1/graphql/`,
            ` ${query}`
            ,
            {
                headers: {
                    "Content-type": "application/graphql",
                },
            });
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}

