import http from "@/utils/http";
import { toProcessType } from "../material";
import { CardTypeEnum } from "@/pages/CreationSpace/components/MaterialCard/MaterialCard";
import { ProjectTypeEnum } from "@/pages/CreationSpace/components/ProjectCard/ProjectCard";
import moment from "moment";

export const getUpdateTime = (unixTimestamp: number) => {
  return moment.unix(unixTimestamp).format("YYYY-MM-DD HH:mm");
};

export interface IMaterialDetail {
  id: string;
  bitrate: string;
  created_at: number;
  creator: string;
  duration: number;
  file_name: string;
  file_size: number;
  file_type: string;
  format_name: string;
  fps: string;
  height: number;
  level: number;
  media_id: string;
  name: string;
  num_frames: string;
  parent_id: string;
  play_info: {
    cover_url: string;
    play_url: string;
  };
  project_id: string;
  search_path: string;
  source: string;
  status: string;
  team_id: string;
  type: number | CardTypeEnum | ProjectTypeEnum;
  updated_at: number;
  width: number;
  frame_snapshot: string;
  use_by_session: string;
  compose_count: string;
}

export const getSimpleMedia = async (id: number): Promise<IMaterialDetail> => {
  const result = await http.post(
    `/api/v1/graphql/`,
    `{material_detail(id:${id}){ 
        id
        project_id      
        level      
        frame_snapshot
        search_path      
        name      
        media_id      
        type      
        file_type      
        file_size      
        format_name      
        bitrate      
        width      
        height      
        duration      
        source      
        status      
        creator      
        created_at      
        updated_at         
        parent_id      
        fps      
        num_frames      
        file_name      
        play_info{              
          play_url             
          cover_url      
        }    
        use_by_session
        compose_count
    }
  }`,
    {
      headers: {
        "Content-Type": "application/graphql",
      },
    },
  );
  return toProcessType(result.data.material_detail);
};
