import { ITransitionEffectItem } from "@/stores/mixCutDetailStore/type/transition";
import http from "@/utils/http";
import { IMaterialDetail } from "../createSpace/graphQ/getSimpleMedia";

export const deleteCustomize = async (
  engineeringId: number,
  materialId: number,
  source: string,
) => {
  const result = await http.delete(`/api/v1/editing/customize`, {
    data: {
      session_id: engineeringId,
      material_id: materialId,
      source: source,
    },
  } as any);
  return result;
};

export const unBindVideoMaterial = async (
  engineeringId: number,
  materialId: number,
) => {
  const result = await http.delete(`/api/v1/editing/customize`, {
    data: {
      session_id: engineeringId,
      material_id: materialId,
      source: "video",
    },
  } as any);
  return result;
};

const searchFiled = () => {
  return `id
  project_id
  level
  search_path
  name
  media_id
  type
  file_type
  file_size
  format_name
  bitrate
  width
  height
  duration
  source
  status
  creator
  created_at
  updated_at
  parent_id
  fps
  num_frames
  file_name
  play_info {
    play_url
    cover_url
  }
  `;
};

const toEngineeringAndSourceGetString = (
  engineeringId: number | string,
  source: string,
) => {
  const id = Number(engineeringId);
  return `{custom_material_list (session_id:${engineeringId} ,source:"${source}"){
    ${searchFiled()}
  }
  }`;
};

export interface ITerbreItemResponse {
  id: string;
  name: string;
  avatar_url: string;
  speaker_style: string;
  speaker_id: string;
  created_at: number;
  updated_at: number;
  url?: string;
}

// interface ITimbreResponse {
//   base_url: string;
//   items: ITerbreItemResponse[];
// }

export interface IFlowerFontItemResponse {
  id: string;
  coverUrl: string;
  content: Record<string, string>;
  create_at: number;
  update_at: number;
  url: string;
}

// interface IFlowerFontResponse {
//   base_url: string;
//   items: IFlowerFontItemResponse[];
// }

export interface IMusicResponse {
  author_name: string;
  cover_url: string;
  created_at: number;
  file_url: string;
  id: number;
  title: string;
  type: number;
  updated_at: number;
  duration: number;
  is_collect: number;
  url: string;
}

interface ITransitionItemResponse {
  content: string;
  cover_url: string;
  created_at: number;
  id: number;
  name: string;
  updated_at: number;
  url: string;
}

// 设置url为图片地址
const toBaseUrlSetItem = <T extends Object>(
  data: T[],
  baseUrl: string,
  key: string,
) => {
  return data.map((item) => {
    return {
      ...item,
      url: baseUrl + item[key],
    };
  });
};

export interface IMixDetailTransitionRequest
  extends Omit<ITransitionItemResponse, "content"> {
  content: ITransitionEffectItem;
}

// 转换content为type类型
const toContentSetType = (
  data: ITransitionItemResponse[],
): IMixDetailTransitionRequest[] => {
  return data.map((item) => {
    return {
      ...item,
      content: JSON.parse(item.content) as ITransitionEffectItem,
    };
  });
};

// 获取配音列表
export const getTimbre = async (
  params,
): Promise<{
  data: ITerbreItemResponse[];
  total: number;
}> => {
  const result = await http.get(`/api/v1/editing/assets/voices`, {
    params,
  });
  return {
    data: toBaseUrlSetItem(
      result.data.items,
      result.data.base_url,
      "avatar_url",
    ),
    total: 100,
  };
};

// 获取花字列表
export const getFlowerFont = async (): Promise<{
  data: IFlowerFontItemResponse[];
  total: number;
}> => {
  const result = await http.get(`/api/v1/editing/assets/fancy_fonts`);
  return {
    data: toBaseUrlSetItem(
      result.data.items,
      result.data.base_url,
      "coverUrl",
    ).map((item) => {
      return {
        ...item,
        content: JSON.parse((item as any).content),
      };
    }) as IFlowerFontItemResponse[],
    total: 100,
  };
};

// 获取专场特效列表
export const getTransitionsList = async (): Promise<{
  data: IMixDetailTransitionRequest[];
  total: number;
}> => {
  const result = await http.get(`/api/v1/editing/assets/transitions`);
  return {
    data: toContentSetType(
      toBaseUrlSetItem(result.data.items, result.data.base_url, "cover_url"),
    ),
    total: 100,
  };
};

export interface ICoverItemResponse {
  url: string;
  created_at: number;
  file_url: string;
  id: number;
  name: string;
  updated_at: number;
}

// 获取封面列表
export const getCoverList = async (): Promise<{
  data: ICoverItemResponse[];
  total: number;
}> => {
  const result = await http.get(`/api/v1/editing/video_cover`);
  return {
    data: result.data.items.map((item) => {
      return {
        ...item,
        url:
          result.data.base_url ||
          "https://zhaixing-dev-00.oss-cn-shanghai.aliyuncs.com" +
            item.cover_url,
      };
    }),
    total: 1000,
  };
};

// 获取官方音乐列表
export const getPublicMusic = async (): Promise<{
  data: IMusicResponse[];
  total: number;
}> => {
  const result = await http.get(`/api/v1/editing/crown_music`);
  return { data: result.data, total: 100 };
};

export const toIdGetMusicList = async (
  id: number,
): Promise<IMusicResponse[]> => {
  const result = await http.get(`/api/v1/editing/music`, {
    params: {
      collection_id: id,
      type: "1",
    },
  });
  const baseUrl = result.data.base_url;
  return (result.data?.items || []).map((item) => {
    return {
      ...item,
      url: baseUrl + item.file_url,
      cover_url: baseUrl + item.cover_url,
    };
  });
};

// 查询自定义音乐列表
export const getCustomMusic = async (): Promise<IMusicResponse[]> => {
  const result = await http.get(`/api/v1/editing/customisation_music`);
  return result.data;
};

export interface IBgResponse {
  url: string;
  created_at: number;
  file_url: string;
  id: number;
  name: string;
  updated_at: number;
}

// 查询官方背景图
export const getPublicBg = async (): Promise<{
  data: IBgResponse[];
  total: number;
}> => {
  const result = await http.get(`/api/v1/editing/assets/background`);
  return {
    data: result.data.items.map((item) => {
      return {
        ...item,
        url: result.data.base_url + item.cover_url,
      };
    }),
    total: 100,
  };
};

// // 查询自定义背景图
// export const getCustomCover = async (): Promise<ITimbreResponse[]> => {
//   const result = await http.get(`/api/v1/editing/customisation_music`);
//   return result.data;
// };

export interface MusicResponse {
  items: IMusicResponse[];
  base_url: string;
}

// 查询音乐列表
export const getMusicList = async (): Promise<any> => {
  const result = await http.get(`/api/v1/editing/music/category?type=1`);
  return result.data.items;
};

// 查找收藏音乐
export const getFavoriteMusicList = async (): Promise<IMusicResponse[]> => {
  const result = await http.get(`/api/v1/editing/music/list`);
  const baseUrl = result.data.base_url;
  return (result.data?.items || []).map((item) => {
    return {
      ...item,
      file_url: baseUrl + item.file_url,
      cover_url: baseUrl + item.cover_url,
      is_collect: true,
    };
  });
};

// 删除自定义音乐
export const deleteCustomizeMusic = async (
  engineeringId: string | number,
  material_id: string | number,
) => {
  const result = await deleteCustomize(
    Number(engineeringId),
    Number(material_id),
    "music",
  );
};

// 收藏音乐
export const collectMusic = async (id: number) => {
  const result = await http.post(`/api/v1/editing/music/favorite`, {
    id,
  });
  return result;
};

// 取消收藏音乐
export const cancelCollectMusic = async (id: number) => {
  const result = await http.delete(`/api/v1/editing/music/${id}`);
  return result;
};

// 绑定封面
export const bindingCover = async (
  engineeringId: number | string,
  materialId: number[],
) => {
  const result = await http.post(`/api/v1/editing/material`, {
    editing_session_id: Number(engineeringId),
    source: "cover",
    material_id: materialId.map((item) => Number(item)),
  });
  return result.data.id;
};
// 查询绑定封面
export const getBindCover = async (engineeringId: number | string) => {
  const result = await http.post(
    `/api/v1/graphql/`,
    `${toEngineeringAndSourceGetString(engineeringId, "cover")}`,
    {
      headers: {
        "Content-type": "application/graphql",
      },
    },
  );
  return result.data?.custom_material_list || [];
};

// 删除封面
export const deleteCustomizeCover = async (
  engineeringId: string | number,
  material_id: string | number,
) => {
  const result = await deleteCustomize(
    Number(engineeringId),
    Number(material_id),
    "cover",
  );
};
// 绑定背景图
export const bindingBgPicture = async (
  engineeringId: number,
  materialId: number[],
) => {
  const result = await http.post(`/api/v1/editing/material`, {
    editing_session_id: engineeringId,
    source: "background",
    material_id: materialId,
  });
  return result?.data?.id;
};

// 查询背景图
export const getBindingBgPicture = async (
  engineeringId: number | string,
): Promise<IMaterialDetail[]> => {
  const result = await http.post(
    `/api/v1/graphql/`,
    `${toEngineeringAndSourceGetString(engineeringId, "background")}`,
    {
      headers: {
        "Content-type": "application/graphql",
      },
    },
  );
  return result.data?.custom_material_list || [];
};

// 删除背景图
export const deleteCustomBackground = async (
  engineeringId: string | number,
  material_id: string | number,
) => {
  const result = await deleteCustomize(
    Number(engineeringId),
    Number(material_id),
    "background",
  );
  return result.data;
};
// 绑定音乐
export const bindingMusic = async (
  engineeringId: number | string,
  materialId: number[],
) => {
  const result = await http.post(`/api/v1/editing/material`, {
    editing_session_id: Number(engineeringId),
    source: "music",
    material_id: materialId,
  });
  return result?.data?.id;
};
// 查询绑定音乐
export const getBindingVideo = async (
  engineeringId: number | string,
): Promise<IMaterialDetail[]> => {
  const result = await http.post(
    `/api/v1/graphql/`,
    `${toEngineeringAndSourceGetString(engineeringId, "music")}`,
    {
      headers: {
        "Content-type": "application/graphql",
      },
    },
  );
  return result.data?.custom_material_list || [];
};
//删除音乐

// 绑定视频
export const bindingVideo = async (
  engineeringId: number | string,
  materialId: number[] | string[],
) => {
  const result = http.post(`/api/v1/editing/material`, {
    editing_session_id: Number(engineeringId),
    source: "video",
    material_id: materialId.map((item) => Number(item)),
  });
  return result;
};
// 查询视频

// 保存功能content

export const saveEngineeringContent = (id: number, content: string) => {
  const result = http.put(`/api/v1/space/session/${id}/content`, {
    content,
  });
  return result;
};

//todo 添加ss字段 ssml

// 获取试听信息
export interface ISpeechRequest {
  line: string; // content
  speaker_id: string; // speaker_id
  speed_ratio: number; // speed_ratio
  volume_ratio: number; // volume_ratio
  pitch_ratio: number; // pitch_ratio
}
export const getAudition = async (data: ISpeechRequest): Promise<string> => {
  const result = await http.post(`/api/v1/editing/audition`, {
    ...data,
    ssml: 1,
    voice_type: "official",
  });
  return result.data.data;
};
