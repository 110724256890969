import { makeAutoObservable } from "mobx";

export interface IFolder {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  // createUserName: string;
}

class FolderStore {
  private folderList: (IFolder | string)[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  getFolderList() {
    return this.folderList;
  }

  setFolderList(folderList: (IFolder | string)[]) {
    this.folderList = folderList;
  }
}

const folderStore = new FolderStore();

export default folderStore;
